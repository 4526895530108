import React, { FunctionComponent } from "react";
import styled from "styled-components";
import androidDownBanner from "../../assets/img/mainSection/android_download_banner.png";
import iosDownBanner from "../../assets/img/mainSection/ios_download_banner.png";
import appImageFirst from "../../assets/img/mainSection/app_image_1.png";
import appImageSecond from "../../assets/img/mainSection/app_image_2_small.png";
import logo from "../../assets/svg/logo.svg";
import eventApp from "../../assets/img/mainSection/event_app.jpg";
import useTrackingBtn from "../../hooks/useTrackingBtn";
import { useEffect } from "react";

interface Props {
  clickAndroidDownload: (position: string) => void;
  clickIosDownload: (position: string) => void;
}

const MainSectionApp: FunctionComponent<Props> = ({
  clickAndroidDownload,
  clickIosDownload,
}) => {
  const {
    handleAppTopGoogleClick,
    handleAppTopAppleClick,
    handleAppInput,
    handleAppKnowledgeTasteClick,
  } = useTrackingBtn();
  const standardWidth = 345;

  const headerMarginTop = 28;
  const marginLeft = 20;
  //Logo
  const logoImageWidth = 51;
  const logoImageHeight = 24;

  //Catchphrase
  const catchWidth = 196;
  const catchHeight = 150;
  const catchFontSize = 34;
  const catchMarginTop = 38;

  // App Image Container
  const appContainerWidht = 338;
  const appContainerMarginTop = 10;

  //Android App Image
  const androidAppImageWidth = 338;
  const androidAppImageHight = 215;

  //Ios App Image
  const iosAppImageWidth = 153;
  const iosAppImageHight = 192;
  const iosAppImageAbsoluteTop = -40;

  //Download Container
  const downloadContainerMarginTop = 15;

  //Download Banner
  const androidBannerWidth = 140;
  const androidBannerHeight = (androidBannerWidth * 48) / 161;
  const iosBannerWidth = 125;
  const iosBannerHeight = (iosBannerWidth * 48) / 144;

  useEffect(() => {
    handleAppInput();
  }, []);

  return (
    <>
      <img
        onClick={() => {
          handleAppKnowledgeTasteClick();
          window.open("https://web.bibly.ai/knowledge_taste");
        }}
        src={eventApp}
        style={{ width: "100%" }}
        alt={"knowledge_taste"}
      />
      <Container standardWidth={standardWidth} marginLeft={marginLeft}>
        <LogoImage
          src={logo}
          headerMarginTop={headerMarginTop}
          logoLeftMargin={marginLeft}
          logoImageWidth={logoImageWidth}
          logoImageHeight={logoImageHeight}
        />
        <Catchphrase
          catchWidth={catchWidth}
          catchHeight={catchHeight}
          catchFontSize={catchFontSize}
          catchMarginTop={catchMarginTop}
          catchMarginLeft={marginLeft}
          standardWidth={standardWidth}
        >
          당신을 위한 <br /> 찾아가는 서점 <br /> 비블리
        </Catchphrase>
        <AppImageContainer
          appContainerWidht={appContainerWidht}
          appContainerMarginTop={appContainerMarginTop}
        >
          <AppFirstImage
            src={appImageFirst}
            androidAppImageWidth={androidAppImageWidth}
            androidAppImageHight={androidAppImageHight}
            standardWidth={standardWidth}
          />
          <AppSecondImage
            src={appImageSecond}
            iosAppImageWidth={iosAppImageWidth}
            iosAppImageHight={iosAppImageHight}
            iosAppImageAbsoluteTop={iosAppImageAbsoluteTop}
            standardWidth={standardWidth}
          />
        </AppImageContainer>
        <BannerContainer
          downloadContainerMarginTop={downloadContainerMarginTop}
          standardWidth={standardWidth}
        >
          <AndroidDownBanner
            onClick={() => {
              handleAppTopGoogleClick();
              clickAndroidDownload("bottom");
            }}
            src={androidDownBanner}
            bannerWidth={androidBannerWidth}
            bannerHeight={androidBannerHeight}
            standardWidth={standardWidth}
          />
          <IosDownBanner
            onClick={() => {
              handleAppTopAppleClick();
              clickIosDownload("bottom");
            }}
            src={iosDownBanner}
            bannerWidth={iosBannerWidth}
            bannerHeight={iosBannerHeight}
            standardWidth={standardWidth}
          />
        </BannerContainer>
      </Container>
    </>
  );
};

interface ContainerProps {
  marginLeft: number;
  standardWidth: number;
}

const Container = styled.div<ContainerProps>`
  margin-left: ${(props) => props.marginLeft}px;
  padding-bottom: 66px;
  position: "relative";
  @media (max-width: ${(props) => props.standardWidth}px) {
    padding-bottom: ${(props) => (66 / props.standardWidth) * 100}vw;
  }
`;

interface LogoImageProps {
  headerMarginTop: number;
  logoImageWidth: number;
  logoImageHeight: number;
  logoLeftMargin: number;
}

const LogoImage = styled.img<LogoImageProps>`
  margin-top: ${(props) => props.headerMarginTop}px;
  width: ${(props) => props.logoImageWidth}px;
  height: ${(props) => props.logoImageHeight}px;
`;

interface CatchphraseProps {
  catchWidth: number;
  catchHeight: number;
  catchFontSize: number;
  catchMarginTop: number;
  catchMarginLeft: number;
  standardWidth: number;
}

const Catchphrase = styled.div<CatchphraseProps>`
  width: ${(props) => props.catchWidth}px;
  height: ${(props) => props.catchHeight}px;
  font-size: ${(props) => props.catchFontSize}px;
  font-weight: 900;
  margin-top: ${(props) => props.catchMarginTop}px;
  line-height: 1.4;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) => (props.catchWidth / props.standardWidth) * 100}vw;
    height: ${(props) => (props.catchHeight / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.catchFontSize / props.standardWidth) * 100}vw;
    margin-top: ${(props) =>
      (props.catchMarginTop / props.standardWidth) * 100}vw;
  }
`;

interface AppImageContainerProps {
  appContainerWidht: number;
  appContainerMarginTop: number;
}

const AppImageContainer = styled.div<AppImageContainerProps>`
  display: flex;
  position: relative;
  justify-content: flex-end;
  margin-top: ${(props) => props.appContainerMarginTop}px;
`;

interface AppFirstImageProps {
  androidAppImageWidth: number;
  androidAppImageHight: number;
  standardWidth: number;
}

const AppFirstImage = styled.img<AppFirstImageProps>`
  width: ${(props) => props.androidAppImageWidth}px;
  height: ${(props) => props.androidAppImageHight}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      (props.androidAppImageWidth / props.standardWidth) * 100}vw;
    height: ${(props) =>
      (props.androidAppImageHight / props.standardWidth) * 100}vw;
  }
`;

interface AppSecondImageProps {
  iosAppImageWidth: number;
  iosAppImageHight: number;
  iosAppImageAbsoluteTop: number;
  standardWidth: number;
}

const AppSecondImage = styled.img<AppSecondImageProps>`
  position: absolute;
  width: ${(props) => props.iosAppImageWidth}px;
  height: ${(props) => props.iosAppImageHight}px;
  top: ${(props) => props.iosAppImageAbsoluteTop}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) => (props.iosAppImageWidth / props.standardWidth) * 100}vw;
    height: ${(props) =>
      (props.iosAppImageHight / props.standardWidth) * 100}vw;
    top: ${(props) =>
      (props.iosAppImageAbsoluteTop / props.standardWidth) * 100}vw;
  }
`;

interface BannerContainerProps {
  downloadContainerMarginTop: number;
  standardWidth: number;
}

const BannerContainer = styled.div<BannerContainerProps>`
  display: flex;
  width: 100%;
  margin-top: ${(props) => props.downloadContainerMarginTop}px;
  @media (max-width: 430px) {
    padding-right: 20px;
    justify-content: center;
  }
`;

interface AndroidDownBannerProps {
  bannerWidth: number;
  bannerHeight: number;
  standardWidth: number;
}

const AndroidDownBanner = styled.img<AndroidDownBannerProps>`
  width: ${(props) => props.bannerWidth}px;
  height: ${(props) => props.bannerHeight}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) => (props.bannerWidth / props.standardWidth) * 100}vw;
    height: ${(props) => (props.bannerHeight / props.standardWidth) * 100}vw;
  }
`;
interface IosDownBannerProps {
  bannerWidth: number;
  bannerHeight: number;
  standardWidth: number;
}
const IosDownBanner = styled.img<IosDownBannerProps>`
  width: ${(props) => props.bannerWidth}px;
  height: ${(props) => props.bannerHeight}px;
  margin-left: 30px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) => (props.bannerWidth / props.standardWidth) * 100}vw;
    height: ${(props) => (props.bannerHeight / props.standardWidth) * 100}vw;
    margin-left: ${(props) => (30 / props.standardWidth) * 100}vw;
  }
`;

export default MainSectionApp;
