import React from "react";
import styled, { css } from "styled-components";
import styles from "../../../styles/styles";
import rightBigImage from "../../../assets/img/curation/firstCuration/right_big_image.png";
import firstBookImage from "../../../assets/img/curation/firstCuration/book_1.png";
import secondBookImage from "../../../assets/img/curation/firstCuration/book_2.png";
import thirdBookImage from "../../../assets/img/curation/firstCuration/book_3.png";
import forthBookImage from "../../../assets/img/curation/firstCuration/book_4.png";
import boxShadow from "../../../assets/img/curation/firstCuration/rectangle_shadow.png";

const FirstCurationApp = () => {
  const standardWidth = 345;
  const marginLeft = 20;

  //Title
  const titleMarginTop = 68;
  const titleFontSize = 20;

  //Sub Title``
  const subTitleMarginTop = 10;
  const subTitleFontSize = 14;
  const subTitlePaddingRight = 20;

  const imageScale = 1.3;
  const imageDivergentPoint = 500;
  //Right Big Image
  // const rightBigImageWidth = 182;
  // const rightBigImageHeight = 306;
  const rightBigImageWidth = 182 * imageScale;
  const rightBigImageHeight = 306 * imageScale;

  // Book Image Container
  const bookImageContainerMarginTop = 16;
  const bookImageContainerMarginLeft = -5;

  //Book Image
  // const bookImageWidth = 76;
  // const bookImageHeight = 109;
  const bookImageWidth = 76 * imageScale;
  const bookImageHeight = 109 * imageScale;
  const bookImageBottomMargin = 50;

  //BottomShade
  const bottomShadeHeight = 71;
  const bottomShadePositionBottom = 0;

  return (
    <Container>
      <Wrapper standardWidth={standardWidth} marginLeft={marginLeft}>
        <Title
          standardWidth={standardWidth}
          titleMarginTop={titleMarginTop}
          titleFontSize={titleFontSize}
          marginLeft={marginLeft}
        >
          딱 한 번 펼쳐본 책
        </Title>
        <SubTitle
          standardWidth={standardWidth}
          subTitleMarginTop={subTitleMarginTop}
          subTitleFontSize={subTitleFontSize}
          subTitlePaddingRight={subTitlePaddingRight}
          marginLeft={marginLeft}
        >
          딱 한번 펼쳐본 중고 서적을 할인된 가격으로 만나보세요.
        </SubTitle>
        <BookContainer
          standardWidth={standardWidth}
          bookImageContainerMarginTop={bookImageContainerMarginTop}
          bookImageContainerMarginLeft={bookImageContainerMarginLeft}
          marginLeft={marginLeft}
        >
          <BookImage
            src={firstBookImage}
            standardWidth={standardWidth}
            bookImageWidth={bookImageWidth}
            bookImageHeight={bookImageHeight}
            bookImageBottomMargin={bookImageBottomMargin}
            imageDivergentPoint={imageDivergentPoint}
          />
          <BookImage
            src={secondBookImage}
            standardWidth={standardWidth}
            bookImageWidth={bookImageWidth}
            bookImageHeight={bookImageHeight}
            bookImageBottomMargin={bookImageBottomMargin}
            imageDivergentPoint={imageDivergentPoint}
          />
          <BookImage
            src={thirdBookImage}
            standardWidth={standardWidth}
            bookImageWidth={bookImageWidth}
            bookImageHeight={bookImageHeight}
            bookImageBottomMargin={bookImageBottomMargin}
            imageDivergentPoint={imageDivergentPoint}
            deletePoint={720}
          />
          <BookImage
            src={forthBookImage}
            standardWidth={standardWidth}
            bookImageWidth={bookImageWidth}
            bookImageHeight={bookImageHeight}
            bookImageBottomMargin={bookImageBottomMargin}
            imageDivergentPoint={imageDivergentPoint}
            deletePoint={620}
          />
          <RightBigImage
            src={rightBigImage}
            standardWidth={standardWidth}
            rightBigImageWidth={rightBigImageWidth}
            rightBigImageHeight={rightBigImageHeight}
            imageDivergentPoint={imageDivergentPoint}
          />
          <BottomShade
            src={boxShadow}
            imageDivergentPoint={imageDivergentPoint}
            bottomShadeHeight={bottomShadeHeight}
            bottomShadePositionBottom={bottomShadePositionBottom}
          ></BottomShade>
        </BookContainer>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

interface WrapperProps {
  standardWidth: number;
  marginLeft: number;
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
`;

interface TitleProps {
  marginLeft: number;
  standardWidth: number;
  titleMarginTop: number;
  titleFontSize: number;
}

const Title = styled.div<TitleProps>`
  margin-top: ${(props) => props.titleMarginTop}px;
  font-size: ${(props) => props.titleFontSize}px;
  font-weight: 900;
  margin-left: ${(props) => props.marginLeft}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.titleMarginTop / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.titleFontSize / props.standardWidth) * 100}vw;
  }
`;

interface SubTitleProps {
  marginLeft: number;
  standardWidth: number;
  subTitleMarginTop: number;
  subTitleFontSize: number;
  subTitlePaddingRight: number;
}

const SubTitle = styled.span<SubTitleProps>`
  display: block;
  font-size: ${(props) => props.subTitleFontSize}px;
  margin-top: ${(props) => props.subTitleMarginTop}px;
  color: ${styles.DARK_GRAY_COLOR};
  margin-left: ${(props) => props.marginLeft}px;
  padding-right: ${(props) => props.subTitlePaddingRight}px;
  line-height: 1.4;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.subTitleMarginTop / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.subTitleFontSize / props.standardWidth) * 100}vw;
  }
`;

interface RightBigImageProps {
  standardWidth: number;
  rightBigImageWidth: number;
  rightBigImageHeight: number;
  imageDivergentPoint: number;
}

const RightBigImage = styled.img<RightBigImageProps>`
  width: ${(props) => props.rightBigImageWidth}px;
  height: ${(props) => props.rightBigImageHeight}px;
  @media (max-width: ${(props) => props.imageDivergentPoint}px) {
    width: ${(props) =>
      (props.rightBigImageWidth / props.imageDivergentPoint) * 100}vw;
    height: ${(props) =>
      (props.rightBigImageHeight / props.imageDivergentPoint) * 100}vw;
  }
`;

interface BookContainerProps {
  standardWidth: number;
  bookImageContainerMarginTop: number;
  bookImageContainerMarginLeft: number;
  marginLeft: number;
}

const BookContainer = styled.div<BookContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin-top: ${(props) => props.bookImageContainerMarginTop}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.bookImageContainerMarginTop / props.standardWidth) * 100}vw;
  }
`;

interface BookImageProps {
  standardWidth: number;
  bookImageWidth: number;
  bookImageHeight: number;
  bookImageBottomMargin: number;
  deletePoint?: number;
  imageDivergentPoint: number;
}

const BookImage = styled.img<BookImageProps>`
  width: ${(props) => props.bookImageWidth}px;
  height: ${(props) => props.bookImageHeight}px;
  margin-bottom: ${(props) => props.bookImageBottomMargin}px;
  ${(props) =>
    props.deletePoint === 720 &&
    css`
      @media (max-width: ${(props) => 720}px) {
        display: none;
      }
    `}
  ${(props) =>
    props.deletePoint === 620 &&
    css`
      @media (max-width: ${(props) => 620}px) {
        display: none;
      }
    `}
  @media (max-width: ${(props) => props.imageDivergentPoint}px) {
    width: ${(props) =>
      (props.bookImageWidth / props.imageDivergentPoint) * 100}vw;
    height: ${(props) =>
      (props.bookImageHeight / props.imageDivergentPoint) * 100}vw;
    margin-bottom: ${(props) =>
      (props.bookImageBottomMargin / props.imageDivergentPoint) * 100}vw;
  }
`;

interface BottomShadeProps {
  imageDivergentPoint: number;
  bottomShadeHeight: number;
  bottomShadePositionBottom: number;
}
const BottomShade = styled.img<BottomShadeProps>`
  position: absolute;
  width: 100%;
  height: ${(props) => props.bottomShadeHeight}px;
  bottom: ${(props) => props.bottomShadePositionBottom}px;
  @media (max-width: ${(props) => props.imageDivergentPoint}px) {
    height: ${(props) =>
      (props.bottomShadeHeight / props.imageDivergentPoint) * 100}vw;
    bottom: ${(props) =>
      (props.bottomShadePositionBottom / props.imageDivergentPoint) * 100}vw;
  }
`;

export default FirstCurationApp;
