import React, { FunctionComponent } from "react";
import SecondCurationApp from "./SecondCurationApp";
import SecondCurationWeb from "./SecondCurationWeb";

interface Props {
  standardWidth: number;
  divergingPoint: boolean;
  marginLeft: number;
  secondCurationImageDivergentPoint: boolean;
}

const SecondCurationPresenter: FunctionComponent<Props> = ({
  standardWidth,
  divergingPoint,
  marginLeft,
  secondCurationImageDivergentPoint,
}) => {
  return divergingPoint ? (
    <SecondCurationApp
      secondCurationImageDivergentPoint={secondCurationImageDivergentPoint}
    />
  ) : (
    <SecondCurationWeb standardWidth={standardWidth} marginLeft={marginLeft} />
  );
};

export default SecondCurationPresenter;
