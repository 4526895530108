import React, { FunctionComponent } from "react";
import styled from "styled-components";
import styles from "../../styles/styles";
import circleContainerFirst from "../../assets/img/howToUse/circle_container_01.png";
import circleContainerSecond from "../../assets/img/howToUse/circle_container_02.png";
import circleContainerThird from "../../assets/img/howToUse/circle_container_03.png";
import circleContainerForth from "../../assets/img/howToUse/circle_container_04.png";
import circleContainerFiveth from "../../assets/img/howToUse/circle_container_05.png";
import arrowRight from "../../assets/img/howToUse/arrow_right.png";

interface Props {
  standardWidth: number;
  marginLeft: number;
}

const HowToUseWeb: FunctionComponent<Props> = ({
  standardWidth,
  marginLeft,
}) => {
  //Wapper
  const wapperPaddingTop = 98;
  const wapperPaddingBottom = 140;

  //Title

  const titleFontSize = 45;

  //SubTitle
  const subTitleMarginTop = 20;
  const subTitleFontSize = 23;

  //PerchaseOrderContainer
  const perchaseContainerMarginTop = 90;

  //PerchaseOrder CircleContainer
  const circleContainerWidth = 155;
  const circleContainerHeigth = 155;

  //CircleInText
  const circleInTextFontSize = 23;
  const circleInTextTop = 40;
  const circleInTextLeft = 35;

  //PerchaseOrder ArroRigth
  const arrowRightWidth = 23;
  const arrowRightHeight = 23;
  const arrowMargin = 52;

  //Dot
  const dotWidth = 4;
  const dotRadius = dotWidth / 2;
  const dotBottomFirst = -10;
  const dotBottomSecond = -20;
  const dotBottomThird = -30;

  //Dot Text
  const dotTextFontSize = 20;
  const dotTextBottom = -58;

  return (
    <Container>
      <Wrapper
        marginLeft={marginLeft}
        standardWidth={standardWidth}
        wapperPaddingTop={wapperPaddingTop}
        wapperPaddingBottom={wapperPaddingBottom}
      >
        <Title titleFontSize={titleFontSize} standardWidth={standardWidth}>
          찾아가는 서점 이용방법
        </Title>
        <SubTitle
          subTitleMarginTop={subTitleMarginTop}
          subTitleFontSize={subTitleFontSize}
          standardWidth={standardWidth}
        >
          배송 먼저 받고, 원하는 책만 구매하세요.
        </SubTitle>
        <PerchaseOrderContainer
          perchaseContainerMarginTop={perchaseContainerMarginTop}
          standardWidth={standardWidth}
        >
          <CirclePositionWrapper>
            <CircleContainer
              src={circleContainerFirst}
              circleContainerWidth={circleContainerWidth}
              circleContainerHeigth={circleContainerHeigth}
              standardWidth={standardWidth}
            />
            <CircleNumber
              circleInTextFontSize={circleInTextFontSize}
              circleInTextTop={circleInTextTop}
              circleInTextLeft={circleInTextLeft}
              standardWidth={standardWidth}
            >
              01
            </CircleNumber>
            <CircleText
              circleInTextFontSize={circleInTextFontSize}
              circleInTextTop={circleInTextTop}
              circleInTextLeft={circleInTextLeft}
              standardWidth={standardWidth}
            >
              주문하기
            </CircleText>
            <Dot
              circleContainerWidth={circleContainerWidth}
              standardWidth={standardWidth}
              dotWidth={dotWidth}
              dotRadius={dotRadius}
              dotBottom={dotBottomFirst}
            />
            <Dot
              circleContainerWidth={circleContainerWidth}
              standardWidth={standardWidth}
              dotWidth={dotWidth}
              dotRadius={dotRadius}
              dotBottom={dotBottomSecond}
            />
            <Dot
              circleContainerWidth={circleContainerWidth}
              standardWidth={standardWidth}
              dotWidth={dotWidth}
              dotRadius={dotRadius}
              dotBottom={dotBottomThird}
            />
            <DotText
              circleContainerWidth={circleContainerWidth}
              standardWidth={standardWidth}
              dotTextFontSize={dotTextFontSize}
              dotTextBottom={dotTextBottom}
            >
              선주문
            </DotText>
          </CirclePositionWrapper>
          <ArrowLeft
            src={arrowRight}
            arrowRightWidth={arrowRightWidth}
            arrowRightHeight={arrowRightHeight}
            arrowMargin={arrowMargin}
            standardWidth={standardWidth}
          />
          <CirclePositionWrapper>
            <CircleContainer
              src={circleContainerSecond}
              circleContainerWidth={circleContainerWidth}
              circleContainerHeigth={circleContainerHeigth}
              standardWidth={standardWidth}
            />
            <CircleNumber
              circleInTextFontSize={circleInTextFontSize}
              circleInTextTop={circleInTextTop}
              circleInTextLeft={circleInTextLeft}
              standardWidth={standardWidth}
            >
              02
            </CircleNumber>
            <CircleText
              circleInTextFontSize={circleInTextFontSize}
              circleInTextTop={circleInTextTop}
              circleInTextLeft={circleInTextLeft}
              standardWidth={standardWidth}
            >
              배송완료
            </CircleText>
          </CirclePositionWrapper>
          <ArrowLeft
            src={arrowRight}
            arrowRightWidth={arrowRightWidth}
            arrowRightHeight={arrowRightHeight}
            arrowMargin={arrowMargin}
            standardWidth={standardWidth}
          />
          <CirclePositionWrapper>
            <CircleContainer
              src={circleContainerThird}
              circleContainerWidth={circleContainerWidth}
              circleContainerHeigth={circleContainerHeigth}
              standardWidth={standardWidth}
            />
            <CircleNumber
              circleInTextFontSize={circleInTextFontSize}
              circleInTextTop={circleInTextTop}
              circleInTextLeft={circleInTextLeft}
              standardWidth={standardWidth}
            >
              03
            </CircleNumber>
            <CircleText
              circleInTextFontSize={circleInTextFontSize}
              circleInTextTop={circleInTextTop}
              circleInTextLeft={circleInTextLeft}
              standardWidth={standardWidth}
            >
              읽어보기
            </CircleText>
          </CirclePositionWrapper>
          <ArrowLeft
            src={arrowRight}
            arrowRightWidth={arrowRightWidth}
            arrowRightHeight={arrowRightHeight}
            arrowMargin={arrowMargin}
            standardWidth={standardWidth}
          />
          <CirclePositionWrapper>
            <CircleContainer
              src={circleContainerForth}
              circleContainerWidth={circleContainerWidth}
              circleContainerHeigth={circleContainerHeigth}
              standardWidth={standardWidth}
            />
            <CircleNumber
              circleInTextFontSize={circleInTextFontSize}
              circleInTextTop={circleInTextTop}
              circleInTextLeft={circleInTextLeft}
              standardWidth={standardWidth}
            >
              04
            </CircleNumber>
            <CircleText
              circleInTextFontSize={circleInTextFontSize}
              circleInTextTop={circleInTextTop}
              circleInTextLeft={circleInTextLeft}
              standardWidth={standardWidth}
            >
              구매결정
            </CircleText>
            <Dot
              circleContainerWidth={circleContainerWidth}
              standardWidth={standardWidth}
              dotWidth={dotWidth}
              dotRadius={dotRadius}
              dotBottom={dotBottomFirst}
            />
            <Dot
              circleContainerWidth={circleContainerWidth}
              standardWidth={standardWidth}
              dotWidth={dotWidth}
              dotRadius={dotRadius}
              dotBottom={dotBottomSecond}
            />
            <Dot
              circleContainerWidth={circleContainerWidth}
              standardWidth={standardWidth}
              dotWidth={dotWidth}
              dotRadius={dotRadius}
              dotBottom={dotBottomThird}
            />
            <DotText
              circleContainerWidth={circleContainerWidth}
              standardWidth={standardWidth}
              dotTextFontSize={dotTextFontSize}
              dotTextBottom={dotTextBottom}
            >
              후결제
            </DotText>
          </CirclePositionWrapper>
          <ArrowLeft
            src={arrowRight}
            arrowRightWidth={arrowRightWidth}
            arrowRightHeight={arrowRightHeight}
            arrowMargin={arrowMargin}
            standardWidth={standardWidth}
          />
          <CirclePositionWrapper>
            <CircleContainer
              src={circleContainerFiveth}
              circleContainerWidth={circleContainerWidth}
              circleContainerHeigth={circleContainerHeigth}
              standardWidth={standardWidth}
            />
            <CircleNumber
              circleInTextFontSize={circleInTextFontSize}
              circleInTextTop={circleInTextTop}
              circleInTextLeft={circleInTextLeft}
              standardWidth={standardWidth}
            >
              05
            </CircleNumber>
            <CircleText
              circleInTextFontSize={circleInTextFontSize}
              circleInTextTop={circleInTextTop}
              circleInTextLeft={circleInTextLeft}
              standardWidth={standardWidth}
            >
              리턴하기
            </CircleText>
          </CirclePositionWrapper>
        </PerchaseOrderContainer>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${styles.GRAY_COLOR};
`;

interface WrapperProps {
  marginLeft: number;
  standardWidth: number;
  wapperPaddingTop: number;
  wapperPaddingBottom: number;
}

const Wrapper = styled.div<WrapperProps>`
  margin-left: ${(props) => props.marginLeft}px;
  padding-top: ${(props) => props.wapperPaddingTop}px;
  padding-bottom: ${(props) => props.wapperPaddingBottom}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-left: ${(props) => (props.marginLeft / props.standardWidth) * 100}vw;
    padding-top: ${(props) =>
      (props.wapperPaddingTop / props.standardWidth) * 100}vw;
    padding-bottom: ${(props) =>
      (props.wapperPaddingBottom / props.standardWidth) * 100}vw;
  }
`;
interface TitleProps {
  titleFontSize: number;
  standardWidth: number;
}

const Title = styled.div<TitleProps>`
  font-size: ${(props) => props.titleFontSize}px;
  font-weight: 900;
  @media (max-width: ${(props) => props.standardWidth}px) {
    font-size: ${(props) =>
      (props.titleFontSize / props.standardWidth) * 100}vw;
  }
`;

interface SubTitleProps {
  subTitleMarginTop: number;
  subTitleFontSize: number;
  standardWidth: number;
}

const SubTitle = styled.div<SubTitleProps>`
  margin-top: ${(props) => props.subTitleMarginTop}px;
  font-size: ${(props) => props.subTitleFontSize}px;
  color: ${styles.DARK_GRAY_COLOR};
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.subTitleMarginTop / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.subTitleFontSize / props.standardWidth) * 100}vw;
  }
`;

interface PerchaseOrderContainerProps {
  perchaseContainerMarginTop: number;
  standardWidth: number;
}

const PerchaseOrderContainer = styled.div<PerchaseOrderContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${(props) => props.perchaseContainerMarginTop}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.perchaseContainerMarginTop / props.standardWidth) * 100}vw;
  }
`;

const CirclePositionWrapper = styled.div`
  position: relative;
`;

interface CircleContainerProps {
  circleContainerWidth: number;
  circleContainerHeigth: number;
  standardWidth: number;
}

const CircleContainer = styled.img<CircleContainerProps>`
  width: ${(props) => props.circleContainerWidth}px;
  height: ${(props) => props.circleContainerHeigth}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      (props.circleContainerWidth / props.standardWidth) * 100}vw;
    height: ${(props) =>
      (props.circleContainerHeigth / props.standardWidth) * 100}vw;
  }
`;

interface CircleInTextProps {
  circleInTextFontSize: number;
  circleInTextTop: number;
  circleInTextLeft: number;
  standardWidth: number;
}

const CircleNumber = styled.span<CircleInTextProps>`
  position: absolute;
  font-family: "jua";
  font-size: ${(props) => props.circleInTextFontSize}px;
  top: ${(props) => props.circleInTextTop}px;
  left: ${(props) => props.circleInTextLeft}px;
  color: white;
  @media (max-width: ${(props) => props.standardWidth}px) {
    font-size: ${(props) =>
      (props.circleInTextFontSize / props.standardWidth) * 100}vw;
    top: ${(props) => (props.circleInTextTop / props.standardWidth) * 100}vw;
    left: ${(props) => (props.circleInTextLeft / props.standardWidth) * 100}vw;
  }
`;

const CircleText = styled.span<CircleInTextProps>`
  position: absolute;
  font-family: "jua";
  font-size: ${(props) => props.circleInTextFontSize}px;
  bottom: ${(props) => props.circleInTextTop}px;
  left: ${(props) => props.circleInTextLeft}px;
  color: ${styles.DARK_GRAY_COLOR};
  @media (max-width: ${(props) => props.standardWidth}px) {
    font-size: ${(props) =>
      (props.circleInTextFontSize / props.standardWidth) * 100}vw;
    bottom: ${(props) => (props.circleInTextTop / props.standardWidth) * 100}vw;
    left: ${(props) => (props.circleInTextLeft / props.standardWidth) * 100}vw;
  }
`;

interface ArrowLeftProps {
  arrowRightWidth: number;
  arrowRightHeight: number;
  arrowMargin: number;
  standardWidth: number;
}

const ArrowLeft = styled.img<ArrowLeftProps>`
  width: ${(props) => props.arrowRightWidth}px;
  height: ${(props) => props.arrowRightHeight}px;
  margin-left: ${(props) => props.arrowMargin}px;
  margin-right: ${(props) => props.arrowMargin}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) => (props.arrowRightWidth / props.standardWidth) * 100}vw;
    height: ${(props) =>
      (props.arrowRightHeight / props.standardWidth) * 100}vw;
    margin-left: ${(props) =>
      (props.arrowMargin / props.standardWidth) * 100}vw;
    margin-right: ${(props) =>
      (props.arrowMargin / props.standardWidth) * 100}vw;
  }
`;

interface DotProps {
  circleContainerWidth: number;
  dotWidth: number;
  dotRadius: number;
  dotBottom: number;
  standardWidth: number;
}

const Dot = styled.span<DotProps>`
  position: absolute;
  width: ${(props) => props.dotWidth}px;
  height: ${(props) => props.dotWidth}px;
  border-radius: ${(props) => props.dotRadius}px;
  background-color: ${styles.ORANGE_COLOR};
  bottom: ${(props) => props.dotBottom}px;
  left: ${(props) => props.circleContainerWidth / 2}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) => (props.dotWidth / props.standardWidth) * 100}vw;
    height: ${(props) => (props.dotWidth / props.standardWidth) * 100}vw;
    border-radius: ${(props) =>
      (props.dotRadius / props.standardWidth / 2) * 100}vw;
    bottom: ${(props) => (props.dotBottom / props.standardWidth) * 100}vw;
    left: ${(props) =>
      (props.circleContainerWidth / props.standardWidth / 2) * 100}vw;
  }
`;

interface DotTextProps {
  circleContainerWidth: number;
  standardWidth: number;
  dotTextFontSize: number;
  dotTextBottom: number;
}

const DotText = styled.span<DotTextProps>`
  position: absolute;
  width: ${(props) => props.circleContainerWidth}px;
  font-family: "jua";
  font-size: ${(props) => props.dotTextFontSize}px;
  color: ${styles.ORANGE_COLOR};
  left: 0px;
  bottom: ${(props) => props.dotTextBottom}px;
  text-align: center;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      (props.circleContainerWidth / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.dotTextFontSize / props.standardWidth) * 100}vw;
    bottom: ${(props) => (props.dotTextBottom / props.standardWidth) * 100}vw;
  }
`;

export default HowToUseWeb;
