import React, { FunctionComponent, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import styled from "styled-components";
import styles from "../../../styles/styles";
import appImageFirst from "../../../assets/img/curation/secondCuration/app_1.png";
import appImageSecond from "../../../assets/img/curation/secondCuration/app_2.png";
import appImageThird from "../../../assets/img/curation/secondCuration/app_3.png";
import appImageForth from "../../../assets/img/curation/secondCuration/app_4.png";

interface Props {
  secondCurationImageDivergentPoint: boolean;
}
const SecondCurationApp: FunctionComponent<Props> = ({
  secondCurationImageDivergentPoint,
}) => {
  const standardWidth = 345;
  const marginLeft = 20;

  //Title
  const titleMarginTop = 18;
  const titleFontSize = 20;

  //Sub Title
  const subTitleMarginTop = 10;
  const subTitleFontSize = 14;
  const subTitlePaddingRight = 20;

  //App Image Container
  const appImageContainerMarginTop = 31;
  const appImageMarginLeft = -15;

  //App Image
  // const appImageWidth = 155;
  // const appImageHeight = 274;
  const appImageScale = 1.124;
  const appImageWidth = 155 * appImageScale;
  const appImageHeight = 274 * appImageScale;

  //PaginationDotContainer
  const paginationDotContainerMarginTop = 0;

  //PaginationDot Long
  const paginationLongDotWidth = 35;
  const paginationLongDotBorderRadius = 2.5;

  //PaginationDot Short
  const paginationShortDotWidth = 5;
  const paginationShortDotHeight = 5;
  const paginationShortDotBorderRadius = 2.5;
  const paginationShortDotMarginRight = 2.5;

  //Swiper Wrapper
  const swiperWrapperWidth = 480;
  const swiperWrapperMarginTop = 31;

  const [currentPage, setCurrentPage] = useState(0);

  const slideEventHandler = (e: any) => {
    setCurrentPage(e.realIndex);
  };

  return (
    <Container>
      <Wrapper standardWidth={standardWidth} marginLeft={marginLeft}>
        <Title
          standardWidth={standardWidth}
          titleMarginTop={titleMarginTop}
          titleFontSize={titleFontSize}
          marginLeft={marginLeft}
        >
          새로운 지식을 찾는 당신에게
        </Title>
        <SubTitle
          standardWidth={standardWidth}
          subTitleMarginTop={subTitleMarginTop}
          subTitleFontSize={subTitleFontSize}
          subTitlePaddingRight={subTitlePaddingRight}
          marginLeft={marginLeft}
        >
          비블리만의 인공지능 알고리즘이 당신의 관심사,
          <br />
          책장을 분석해 취향에 꼭 맞는 책을 추천해드려요.
        </SubTitle>
        <div
          style={{
            display: "flex",

            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {secondCurationImageDivergentPoint ? (
            <SwiperWrapper
              standardWidth={standardWidth}
              swiperWrapperWidth={swiperWrapperWidth}
              swiperWrapperMarginTop={swiperWrapperMarginTop}
            >
              <Swiper
                spaceBetween={50}
                lazy={true}
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
                onSlideChange={(e) => slideEventHandler(e)}
              >
                <SwiperSlide>
                  <AppImage
                    src={appImageFirst}
                    standardWidth={standardWidth}
                    appImageWidth={appImageWidth}
                    appImageHeight={appImageHeight}
                  />
                  <AppImage
                    src={appImageSecond}
                    standardWidth={standardWidth}
                    appImageWidth={appImageWidth}
                    appImageHeight={appImageHeight}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <AppImage
                    src={appImageThird}
                    standardWidth={standardWidth}
                    appImageWidth={appImageWidth}
                    appImageHeight={appImageHeight}
                  />
                  <AppImage
                    src={appImageForth}
                    standardWidth={standardWidth}
                    appImageWidth={appImageWidth}
                    appImageHeight={appImageHeight}
                  />
                </SwiperSlide>
              </Swiper>
              <PaginationDotContainer
                standardWidth={standardWidth}
                paginationDotContainerMarginTop={
                  paginationDotContainerMarginTop
                }
              >
                <PaginationShortDot
                  currentPage={currentPage === 0 ? true : false}
                  standardWidth={standardWidth}
                  paginationShortDotWidth={paginationShortDotWidth}
                  paginationShortDotHeight={paginationShortDotHeight}
                  paginationShortDotBorderRadius={
                    paginationShortDotBorderRadius
                  }
                  paginationShortDotMarginRight={paginationShortDotMarginRight}
                  paginationLongDotWidth={paginationLongDotWidth}
                  paginationLongDotBorderRadius={paginationLongDotBorderRadius}
                />
                <PaginationShortDot
                  currentPage={currentPage === 1 ? true : false}
                  standardWidth={standardWidth}
                  paginationShortDotWidth={paginationShortDotWidth}
                  paginationShortDotHeight={paginationShortDotHeight}
                  paginationShortDotBorderRadius={
                    paginationShortDotBorderRadius
                  }
                  paginationShortDotMarginRight={paginationShortDotMarginRight}
                  paginationLongDotWidth={paginationLongDotWidth}
                  paginationLongDotBorderRadius={paginationLongDotBorderRadius}
                />
              </PaginationDotContainer>
            </SwiperWrapper>
          ) : (
            <AppImageContainer
              standardWidth={standardWidth}
              appImageContainerMarginTop={appImageContainerMarginTop}
              appImageMarginLeft={appImageMarginLeft}
              marginLeft={marginLeft}
            >
              <AppImage
                src={appImageFirst}
                standardWidth={standardWidth}
                appImageWidth={appImageWidth}
                appImageHeight={appImageHeight}
              />
              <AppImage
                src={appImageSecond}
                standardWidth={standardWidth}
                appImageWidth={appImageWidth}
                appImageHeight={appImageHeight}
              />
              <AppImage
                src={appImageThird}
                standardWidth={standardWidth}
                appImageWidth={appImageWidth}
                appImageHeight={appImageHeight}
              />
              <AppImage
                src={appImageForth}
                standardWidth={standardWidth}
                appImageWidth={appImageWidth}
                appImageHeight={appImageHeight}
              />
            </AppImageContainer>
          )}
        </div>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

interface WrapperProps {
  standardWidth: number;
  marginLeft: number;
}

const Wrapper = styled.div<WrapperProps>``;

interface TitleProps {
  standardWidth: number;
  titleMarginTop: number;
  titleFontSize: number;
  marginLeft: number;
}

const Title = styled.div<TitleProps>`
  margin-top: ${(props) => props.titleMarginTop}px;
  font-size: ${(props) => props.titleFontSize}px;
  font-weight: 900;
  margin-left: ${(props) => props.marginLeft}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.titleMarginTop / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.titleFontSize / props.standardWidth) * 100}vw;
  }
`;

interface SubTitleProps {
  standardWidth: number;
  subTitleMarginTop: number;
  subTitleFontSize: number;
  subTitlePaddingRight: number;
  marginLeft: number;
}

const SubTitle = styled.span<SubTitleProps>`
  display: block;
  font-size: ${(props) => props.subTitleFontSize}px;
  margin-top: ${(props) => props.subTitleMarginTop}px;
  color: ${styles.DARK_GRAY_COLOR};
  padding-right: ${(props) => props.subTitlePaddingRight}px;
  line-height: 1.4;
  margin-left: ${(props) => props.marginLeft}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.subTitleMarginTop / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.subTitleFontSize / props.standardWidth) * 100}vw;
  }
`;

interface AppImageContainerProps {
  standardWidth: number;
  appImageContainerMarginTop: number;
  appImageMarginLeft: number;
  marginLeft: number;
}
const AppImageContainer = styled.div<AppImageContainerProps>`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => props.appImageContainerMarginTop}px;
  justify-content: center;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.appImageContainerMarginTop / props.standardWidth) * 100}vw;
    margin-left: ${(props) =>
      (props.appImageMarginLeft / props.standardWidth) * 100}vw;
  }
  @media (max-width: ${(props) => 720}px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 10px;
  }
  @media (max-width: ${(props) => 547}px) {
    flex-wrap: nowrap;
  }
`;

interface AppImageProps {
  standardWidth: number;
  appImageWidth: number;
  appImageHeight: number;
}

const AppImage = styled.img<AppImageProps>`
  width: ${(props) => props.appImageWidth}px;
  height: ${(props) => props.appImageHeight}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) => (props.appImageWidth / props.standardWidth) * 100}vw;
    height: ${(props) => (props.appImageHeight / props.standardWidth) * 100}vw;
  }
  @media (max-width: ${(props) => 547}px) {
    width: ${(props) => (props.appImageWidth / 547) * 100 * 1.38}vw;
    height: ${(props) => (props.appImageHeight / 547) * 100 * 1.38}vw;
  }
`;

interface PaginationDotContainerProps {
  standardWidth: number;
  paginationDotContainerMarginTop: number;
}

const PaginationDotContainer = styled.div<PaginationDotContainerProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => props.paginationDotContainerMarginTop}px;
`;

interface PaginationShortDotProps {
  currentPage: boolean;
  standardWidth: number;
  paginationShortDotWidth: number;
  paginationShortDotHeight: number;
  paginationShortDotBorderRadius: number;
  paginationShortDotMarginRight: number;
  paginationLongDotWidth: number;
  paginationLongDotBorderRadius: number;
}

const PaginationShortDot = styled.span<PaginationShortDotProps>`
  width: ${(props) =>
    props.currentPage
      ? props.paginationLongDotWidth
      : props.paginationShortDotWidth}px;
  height: ${(props) => props.paginationShortDotHeight}px;
  border-radius: ${(props) => props.paginationShortDotBorderRadius}px;
  margin-left: ${(props) => props.paginationShortDotMarginRight}px;
  background-color: ${(props) =>
    props.currentPage ? "black" : styles.MIDDLE_GRAY_COLOR};
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      ((props.currentPage
        ? props.paginationLongDotWidth
        : props.paginationShortDotWidth) /
        props.standardWidth) *
      100}vw;
    height: ${(props) =>
      (props.paginationShortDotHeight / props.standardWidth) * 100}vw;
    border-radius: ${(props) =>
      (props.paginationShortDotBorderRadius / props.standardWidth) * 100}vw;
    margin-left: ${(props) =>
      (props.paginationShortDotMarginRight / props.standardWidth) * 100}vw;
  }
`;

interface SwiperWrapperProps {
  standardWidth: number;
  swiperWrapperWidth: number;
  swiperWrapperMarginTop: number;
}

const SwiperWrapper = styled.div<SwiperWrapperProps>`
  width: ${(props) => props.swiperWrapperWidth}px;
  margin-top: ${(props) => props.swiperWrapperMarginTop}px;
  @media (max-width: ${(props) => 540}px) {
    width: ${(props) => (props.swiperWrapperWidth / 540) * 100}vw;
  }
`;

export default SecondCurationApp;
