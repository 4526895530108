import React, { FunctionComponent } from "react";
import FirstCurationApp from "./FirstCurationApp";
import FirstCurationWeb from "./FirstCurationWeb";

interface Props {
  standardWidth: number;
  divergingPoint: boolean;
  marginLeft: number;
}

const FirstCurationPresenter: FunctionComponent<Props> = ({
  standardWidth,
  divergingPoint,
  marginLeft,
}) => {
  return divergingPoint ? (
    <FirstCurationApp />
  ) : (
    <FirstCurationWeb standardWidth={standardWidth} marginLeft={marginLeft} />
  );
};

export default FirstCurationPresenter;
