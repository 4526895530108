import React from "react";
import View from "./screen/View";
import GlobalStyles from "./styles/GlobalStyles";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import ReactPixel from "react-facebook-pixel";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

const App = () => {
  SwiperCore.use([Autoplay, Navigation]);

  const options: any = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  ReactPixel.init("259059335633534", options);

  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact component={View} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
      <GlobalStyles />
    </>
  );
};

export default App;
