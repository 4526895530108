import React, { FunctionComponent } from "react";
import styled from "styled-components";
import styles from "../../../styles/styles";
import rightBigImage from "../../../assets/img/curation/firstCuration/right_big_image.png";
import firstBookImage from "../../../assets/img/curation/firstCuration/book_1.png";
import secondBookImage from "../../../assets/img/curation/firstCuration/book_2.png";
import thirdBookImage from "../../../assets/img/curation/firstCuration/book_3.png";
import forthBookImage from "../../../assets/img/curation/firstCuration/book_4.png";
import boxShadow from "../../../assets/img/curation/firstCuration/rectangle_shadow.png";

interface Props {
  standardWidth: number;
  marginLeft: number;
}

const FirstCurationWeb: FunctionComponent<Props> = ({
  standardWidth,
  marginLeft,
}) => {
  const imageSizeDivergentPoint = 1920;
  //Title
  const titleMarginTop = 100;
  const titleFontSize = 45;

  //Sub Title
  const subTitleMarginTop = 20;
  const subTitleFontSize = 23;

  //Right Big Image
  const rightBigImageWidth = 396 + 26;
  const rightBigImageHeight = 664 + 13;

  // Book Image Container
  const bookImageContainerMarginTop = 151;

  //Book Image
  const bookImageWidth = 160 + 30;
  const bookImageHeight = 232 + 15;
  const bookImageMarginRight = 35;

  //BottomShade

  const bottomShadeHeight = 125;
  const bottomShadePositionBottom = -87;

  return (
    <Container>
      <Wrapper standardWidth={standardWidth} marginLeft={marginLeft}>
        <Title
          standardWidth={standardWidth}
          titleMarginTop={titleMarginTop}
          titleFontSize={titleFontSize}
        >
          딱 한 번 펼쳐본 책
        </Title>
        <SubTitle
          standardWidth={standardWidth}
          subTitleMarginTop={subTitleMarginTop}
          subTitleFontSize={subTitleFontSize}
        >
          딱 한번 펼쳐본 중고 서적을 할인된 가격으로 만나보세요.
        </SubTitle>
        <BookContainer
          standardWidth={standardWidth}
          imageSizeDivergentPoint={imageSizeDivergentPoint}
          bookImageContainerMarginTop={bookImageContainerMarginTop}
        >
          <BookImage
            src={firstBookImage}
            imageSizeDivergentPoint={imageSizeDivergentPoint}
            standardWidth={standardWidth}
            bookImageWidth={bookImageWidth}
            bookImageHeight={bookImageHeight}
            bookImageMarginRight={bookImageMarginRight}
          />
          <BookImage
            src={secondBookImage}
            imageSizeDivergentPoint={imageSizeDivergentPoint}
            standardWidth={standardWidth}
            bookImageWidth={bookImageWidth}
            bookImageHeight={bookImageHeight}
            bookImageMarginRight={bookImageMarginRight}
          />
          <BookImage
            src={thirdBookImage}
            imageSizeDivergentPoint={imageSizeDivergentPoint}
            standardWidth={standardWidth}
            bookImageWidth={bookImageWidth}
            bookImageHeight={bookImageHeight}
            bookImageMarginRight={bookImageMarginRight}
          />
          <BookImage
            src={forthBookImage}
            imageSizeDivergentPoint={imageSizeDivergentPoint}
            standardWidth={standardWidth}
            bookImageWidth={bookImageWidth}
            bookImageHeight={bookImageHeight}
            bookImageMarginRight={bookImageMarginRight}
          />
        </BookContainer>
      </Wrapper>
      <Wrapper standardWidth={standardWidth} marginLeft={0}>
        <RightBigImage
          src={rightBigImage}
          imageSizeDivergentPoint={imageSizeDivergentPoint}
          standardWidth={standardWidth}
          rightBigImageWidth={rightBigImageWidth}
          rightBigImageHeight={rightBigImageHeight}
        />
      </Wrapper>
      <BottomShade
        src={boxShadow}
        imageSizeDivergentPoint={imageSizeDivergentPoint}
        bottomShadeHeight={bottomShadeHeight}
        bottomShadePositionBottom={bottomShadePositionBottom}
      ></BottomShade>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

interface WrapperProps {
  standardWidth: number;
  marginLeft: number;
}

const Wrapper = styled.div<WrapperProps>`
  margin-left: ${(props) => props.marginLeft}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-left: ${(props) => (props.marginLeft / props.standardWidth) * 100}vw;
  }
`;

interface TitleProps {
  standardWidth: number;
  titleMarginTop: number;
  titleFontSize: number;
}

const Title = styled.div<TitleProps>`
  margin-top: ${(props) => props.titleMarginTop}px;
  font-size: ${(props) => props.titleFontSize}px;
  font-weight: 900;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.titleMarginTop / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.titleFontSize / props.standardWidth) * 100}vw;
  }
`;

interface SubTitleProps {
  standardWidth: number;
  subTitleMarginTop: number;
  subTitleFontSize: number;
}

const SubTitle = styled.span<SubTitleProps>`
  display: block;
  font-size: ${(props) => props.subTitleFontSize}px;
  margin-top: ${(props) => props.subTitleMarginTop}px;
  color: ${styles.DARK_GRAY_COLOR};
  line-height: 1.4;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.subTitleMarginTop / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.subTitleFontSize / props.standardWidth) * 100}vw;
  }
`;

interface RightBigImageProps {
  imageSizeDivergentPoint: number;
  standardWidth: number;
  rightBigImageWidth: number;
  rightBigImageHeight: number;
}

const RightBigImage = styled.img<RightBigImageProps>`
  position: absolute;
  top: 0;
  width: ${(props) => props.rightBigImageWidth}px;
  height: ${(props) => props.rightBigImageHeight}px;
  @media (max-width: ${(props) => props.imageSizeDivergentPoint}px) {
    width: ${(props) =>
      (props.rightBigImageWidth / props.imageSizeDivergentPoint) * 100}vw;
    height: ${(props) =>
      (props.rightBigImageHeight / props.imageSizeDivergentPoint) * 100}vw;
  }
`;

interface BookContainerProps {
  imageSizeDivergentPoint: number;
  standardWidth: number;
  bookImageContainerMarginTop: number;
}

const BookContainer = styled.div<BookContainerProps>`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => props.bookImageContainerMarginTop}px;
  margin-left: ${(props) => -20}px;
  @media (max-width: ${(props) => props.imageSizeDivergentPoint}px) {
    margin-top: ${(props) =>
      (props.bookImageContainerMarginTop / props.imageSizeDivergentPoint) *
      100}vw;
    margin-left: ${(props) => (-20 / props.imageSizeDivergentPoint) * 100}vw;
  }
`;

interface BookImageProps {
  imageSizeDivergentPoint: number;
  standardWidth: number;
  bookImageWidth: number;
  bookImageHeight: number;
  bookImageMarginRight: number;
}

const BookImage = styled.img<BookImageProps>`
  width: ${(props) => props.bookImageWidth}px;
  height: ${(props) => props.bookImageHeight}px;
  margin-right: ${(props) => props.bookImageMarginRight}px;
  object-fit: contain;
  @media (max-width: ${(props) => props.imageSizeDivergentPoint}px) {
    width: ${(props) =>
      (props.bookImageWidth / props.imageSizeDivergentPoint) * 100}vw;
    height: ${(props) =>
      (props.bookImageHeight / props.imageSizeDivergentPoint) * 100}vw;
    margin-right: ${(props) =>
      (props.bookImageMarginRight / props.standardWidth) * 100}vw;
  }
`;

interface BottomShadeProps {
  imageSizeDivergentPoint: number;
  bottomShadeHeight: number;
  bottomShadePositionBottom: number;
}
const BottomShade = styled.img<BottomShadeProps>`
  position: absolute;
  width: 100%;
  height: ${(props) => props.bottomShadeHeight}px;
  bottom: ${(props) => props.bottomShadePositionBottom}px;
  align-items: flex-start;
  @media (max-width: ${(props) => props.imageSizeDivergentPoint}px) {
    height: ${(props) =>
      (props.bottomShadeHeight / props.imageSizeDivergentPoint) * 100}vw;
    bottom: ${(props) =>
      (props.bottomShadePositionBottom / props.imageSizeDivergentPoint) *
      100}vw;
  }
`;

export default FirstCurationWeb;
