import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import styles from "../../styles/styles";
import androidDownloadBanner from "../../assets/img/mainSection/android_download_banner.png";
import iosDownloadBanner from "../../assets/img/mainSection/ios_download_banner.png";
import blogIcon from "../../assets/img/footer/blog_icon.png";
import instaIcon from "../../assets/img/footer/insta_icon.png";
import kakaoIcon from "../../assets/img/footer/kakao_icon.png";
import blogIconHover from "../../assets/img/footer/blog_icon_hover.png";
import instaIconHover from "../../assets/img/footer/insta_icon_hover.png";
import kakaoIconHover from "../../assets/img/footer/kakao_icon_hover.png";
import ReactPixel from "react-facebook-pixel";
import useTrackingBtn from "../../hooks/useTrackingBtn";
interface Props {
  standardWidth: number;
  marginLeft: number;
  clickAndroidDownload: (position: string) => void;
  clickIosDownload: (position: string) => void;
}

const FooterWeb: FunctionComponent<Props> = ({
  standardWidth,
  marginLeft,
  clickAndroidDownload,
  clickIosDownload,
}) => {
  const {
    handleWebBottomGoogleClick,
    handleWebBottomAppleClcik,
    handleWebBlogClick,
    handleWebInstClick,
    handleWebKakaoClick,
  } = useTrackingBtn();

  const marginTop = 150;
  const marginBottom = 66;
  //Wrapper
  const wrapperMarginTop = 30;

  //Title
  const titleFontSize = 30;

  //SubTitle
  const subTitleFontSize = 20;
  const subTitleMarginTop = 10;

  //CompanyInfo
  const companyInfMarginTop = 30;
  const companyInfoFontSize = 13;

  //Download Banner Wrapper
  const bannerWrapperMarginRight = 310;

  //Download Banner
  const downloadBannerWidth = 175;
  const downloadBannerHeight = 58;
  const andDownloadBannerRightMargin = 32;
  const iosDownloadBannerRightMargin = 0;

  //Icon Wrapper
  const iconWrapperWidth = standardWidth - 40;
  const iconWrapperMarginTop = 58;

  //Icon
  const iconWidth = 44;
  const iconRightMargin = 30;

  const [blogHover, setBlogHover] = useState(false);
  const [instaHover, setInstaHover] = useState(false);
  const [kakaoHover, setKakaoHover] = useState(false);

  const blogMouseHandle = (over: boolean) => {
    setBlogHover(over);
  };

  const instaMouseHandle = (over: boolean) => {
    setInstaHover(over);
  };

  const kakaoMouseHandle = (over: boolean) => {
    setKakaoHover(over);
  };

  useEffect(() => {
    const blog = document.getElementById("blog_icon");
    const insta = document.getElementById("insta_icon");
    const kakao = document.getElementById("kakao_icon");

    blog?.addEventListener("mouseover", () => blogMouseHandle(true));
    blog?.addEventListener("mouseleave", () => blogMouseHandle(false));

    insta?.addEventListener("mouseover", () => instaMouseHandle(true));
    insta?.addEventListener("mouseleave", () => instaMouseHandle(false));

    kakao?.addEventListener("mouseover", () => kakaoMouseHandle(true));
    kakao?.addEventListener("mouseleave", () => kakaoMouseHandle(false));
  }, []);

  return (
    <Container
      standardWidth={standardWidth}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <Wrapper
        wrapperMarginTop={wrapperMarginTop}
        marginLeft={marginLeft}
        standardWidth={standardWidth}
      >
        <Title titleFontSize={titleFontSize} standardWidth={standardWidth}>
          인공지능 서점 비블리
        </Title>
        <SubTitle
          standardWidth={standardWidth}
          subTitleFontSize={subTitleFontSize}
          subTitleMarginTop={subTitleMarginTop}
        >
          지식을 접하는 새로운 세상
        </SubTitle>
        <CompanyInfo
          standardWidth={standardWidth}
          companyInfMarginTop={companyInfMarginTop}
          companyInfoFontSize={companyInfoFontSize}
        >
          <span style={{ fontWeight: 500 }}>(주) 라이앤캐쳐스</span> &nbsp;|
          &nbsp; 744-88-00108 &nbsp; |&nbsp; 대표이사: 허윤
          <br />
          서울시 서초구 강남대로 527 브랜드칸타워 13층 &nbsp;|&nbsp;
          02-6954-0730 &nbsp;|&nbsp; help@bibly.kr &nbsp;|&nbsp; © 2020 bibly
          all rights reserved.
        </CompanyInfo>
      </Wrapper>
      <DownloadBannerWrapper
        wrapperMarginTop={wrapperMarginTop}
        standardWidth={standardWidth}
        bannerWrapperMarginRight={bannerWrapperMarginRight}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <DownloadBanner
            onClick={() => {
              handleWebBottomGoogleClick();
              clickAndroidDownload("bottom");
            }}
            src={androidDownloadBanner}
            standardWidth={standardWidth}
            downloadBannerWidth={downloadBannerWidth}
            downloadBannerHeight={downloadBannerHeight}
            marginRight={andDownloadBannerRightMargin}
          />
          <div>
            <DownloadBanner
              onClick={() => {
                handleWebBottomAppleClcik();
                clickIosDownload("bottom");
              }}
              src={iosDownloadBanner}
              standardWidth={standardWidth}
              downloadBannerWidth={downloadBannerWidth}
              downloadBannerHeight={downloadBannerHeight}
              marginRight={iosDownloadBannerRightMargin}
            />
          </div>
        </div>
        <IconWrapper
          standardWidth={standardWidth}
          iconWrapperWidth={iconWrapperWidth}
          iconWrapperMarginTop={iconWrapperMarginTop}
        >
          <IconImage
            onClick={() => {
              handleWebBlogClick();
              window.open("https://blog.naver.com/bibly_book/");
              ReactPixel.track("Clicked Blog");
            }}
            id={"blog_icon"}
            src={blogHover ? blogIconHover : blogIcon}
            standardWidth={standardWidth}
            iconWidth={iconWidth}
            iconRightMargin={iconRightMargin}
          />
          <IconImage
            onClick={() => {
              handleWebInstClick();
              window.open("https://www.instagram.com/bibly_official/");
              ReactPixel.track("Clicked Insta");
            }}
            id={"insta_icon"}
            src={instaHover ? instaIconHover : instaIcon}
            standardWidth={standardWidth}
            iconWidth={iconWidth}
            iconRightMargin={iconRightMargin}
          />
          <IconImage
            onClick={() => {
              handleWebKakaoClick();
              window.open("http://pf.kakao.com/_xgxkjxoxl");
              ReactPixel.track("Clicked Kakao");
            }}
            id={"kakao_icon"}
            src={kakaoHover ? kakaoIconHover : kakaoIcon}
            standardWidth={standardWidth}
            iconWidth={iconWidth}
            iconRightMargin={0}
          />
        </IconWrapper>
      </DownloadBannerWrapper>
    </Container>
  );
};

interface ContainerProps {
  standardWidth: number;
  marginTop: number;
  marginBottom: number;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  border-top: solid 2px #f0f0f0;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) => (props.marginTop / props.standardWidth) * 100}vw;
    margin-bottom: ${(props) =>
      (props.marginBottom / props.standardWidth) * 100}vw;
  }
`;

interface WrapperProps {
  standardWidth: number;
  marginLeft: number;
  wrapperMarginTop: number;
}

const Wrapper = styled.div<WrapperProps>`
  margin-top: ${(props) => props.wrapperMarginTop}px;
  margin-left: ${(props) => props.marginLeft}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.wrapperMarginTop / props.standardWidth) * 100}vw;
    margin-left: ${(props) => (props.marginLeft / props.standardWidth) * 100}vw;
  }
`;

interface DownloadBannerWrapperProps {
  standardWidth: number;
  wrapperMarginTop: number;
  bannerWrapperMarginRight: number;
}

const DownloadBannerWrapper = styled.div<DownloadBannerWrapperProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.wrapperMarginTop}px;
  margin-right: ${(props) => props.bannerWrapperMarginRight}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.wrapperMarginTop / props.standardWidth) * 100}vw;
    margin-right: ${(props) =>
      (props.bannerWrapperMarginRight / props.standardWidth) * 100}vw;
  }
`;

interface TitleProps {
  standardWidth: number;
  titleFontSize: number;
}

const Title = styled.div<TitleProps>`
  font-weight: 700;
  font-size: ${(props) => props.titleFontSize}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    font-size: ${(props) =>
      (props.titleFontSize / props.standardWidth) * 100}vw;
  }
`;

interface SubTitleProps {
  standardWidth: number;
  subTitleFontSize: number;
  subTitleMarginTop: number;
}

const SubTitle = styled.div<SubTitleProps>`
  font-size: ${(props) => props.subTitleFontSize}px;
  margin-top: ${(props) => props.subTitleMarginTop}px;
  font-weight: 300;
  @media (max-width: ${(props) => props.standardWidth}px) {
    font-size: ${(props) =>
      (props.subTitleFontSize / props.standardWidth) * 100}vw;
  }
`;

interface CompanyInfoProps {
  standardWidth: number;
  companyInfMarginTop: number;
  companyInfoFontSize: number;
}

const CompanyInfo = styled.div<CompanyInfoProps>`
  margin-top: ${(props) => props.companyInfMarginTop}px;
  font-size: ${(props) => props.companyInfoFontSize}px;
  line-height: 1.8;
  color: ${styles.DARK_GRAY_COLOR};
  font-weight: 300;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.companyInfMarginTop / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.companyInfoFontSize / props.standardWidth) * 100}vw;
  }
`;

interface DownloadBannerProps {
  standardWidth: number;
  downloadBannerWidth: number;
  downloadBannerHeight: number;
  marginRight: number;
}

const DownloadBanner = styled.img<DownloadBannerProps>`
  width: ${(props) => props.downloadBannerWidth}px;
  height: ${(props) => props.downloadBannerHeight}px;
  margin-right: ${(props) => props.marginRight}px;
  cursor: pointer;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      (props.downloadBannerWidth / props.standardWidth) * 100}vw;
    height: ${(props) =>
      (props.downloadBannerHeight / props.standardWidth) * 100}vw;
    margin-right: ${(props) =>
      (props.marginRight / props.standardWidth) * 100}vw;
  }
`;

interface IconWrapperProps {
  standardWidth: number;
  iconWrapperWidth: number;
  iconWrapperMarginTop: number;
}

const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  justify-content: flex-end;
  margin-top: ${(props) => props.iconWrapperMarginTop}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.iconWrapperMarginTop / props.standardWidth) * 100}vw;
  }
`;

interface IconImageProps {
  standardWidth: number;
  iconWidth: number;
  iconRightMargin: number;
}
const IconImage = styled.img<IconImageProps>`
  width: ${(props) => props.iconWidth}px;
  height: ${(props) => props.iconWidth}px;
  margin-right: ${(props) => props.iconRightMargin}px;
  cursor: pointer;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) => (props.iconWidth / props.standardWidth) * 100}vw;
    height: ${(props) => (props.iconWidth / props.standardWidth) * 100}vw;
    margin-right: ${(props) =>
      (props.iconRightMargin / props.standardWidth) * 100}vw;
  }
`;

export default FooterWeb;
