import React, { FunctionComponent } from "react";
import MainSectionApp from "./MainSectionApp";
import MainSectionWeb from "./MainSectionWeb";

interface Props {
  standardWidth: number;
  divergingPoint: boolean;
  marginLeft: number;
  clickAndroidDownload: (position: string) => void;
  clickIosDownload: (position: string) => void;
}

const MainSectionPresenter: FunctionComponent<Props> = ({
  standardWidth,
  divergingPoint,
  marginLeft,
  clickAndroidDownload,
  clickIosDownload,
}) => {
  return divergingPoint ? (
    <MainSectionApp
      clickAndroidDownload={clickAndroidDownload}
      clickIosDownload={clickIosDownload}
    />
  ) : (
    <MainSectionWeb
      standardWidth={standardWidth}
      marginLeft={marginLeft}
      clickAndroidDownload={clickAndroidDownload}
      clickIosDownload={clickIosDownload}
    />
  );
};

export default MainSectionPresenter;
