import React, { FunctionComponent } from "react";
import styled from "styled-components";
import bookEdge from "../../assets/img/mainSection/book_edge.png";
import androidDownBanner from "../../assets/img/mainSection/android_download_banner.png";
import iosDownBanner from "../../assets/img/mainSection/ios_download_banner.png";
import appImageFirst from "../../assets/img/mainSection/app_image_1.png";
import appImageSecond from "../../assets/img/mainSection/app_image_2.png";
import logo from "../../assets/svg/logo.svg";
import eventWeb from "../../assets/img/mainSection/event_web.jpg";
import useTrackingBtn from "../../hooks/useTrackingBtn";
import { useEffect } from "react";

interface Props {
  standardWidth: number;
  marginLeft: number;
  clickAndroidDownload: (position: string) => void;
  clickIosDownload: (position: string) => void;
}

const MainSectionPresenter: FunctionComponent<Props> = ({
  standardWidth,
  marginLeft,
  clickAndroidDownload,
  clickIosDownload,
}) => {
  const {
    handleWebTopGoogleClick,
    handleWebTopAppleClick,
    handleWebInput,
    handleWebKnowledgeTasteClick,
  } = useTrackingBtn();

  //Header Height
  const headerHeight = 55;

  //Logo
  const logoWidth = 86;
  const logoHeight = 40;
  const logoTop = 34.5;

  //Catchphrase
  const catchWidth = 380;
  const catchHeight = 246;
  const catchFontSize = 55;
  const catchMarginTop = 140;

  //Download Banner
  const bannerContainerMarginTop = 75;
  const androidBannerWidth = 258;
  const androidBannerHeight = 77;
  const bannerBetweenMargin = 30;
  const iosBannerWidth = 258;
  const iosBannerHeight = 86;

  // App Image Container
  const appContainerWidht = 1125;
  const appContainerMarginTop = 145;
  const appContainerMarginLeft = 98;

  //Android App Image
  const androidAppImageWidth = 901;
  const androidAppImageHight = 574;
  const androidAppImageMarginTop = 76;

  //Ios App Image
  const iosAppImageWidth = 581;
  const iosAppImageHight = 508;
  const iosAppImageLeft = 520;

  useEffect(() => {
    handleWebInput();
  }, []);

  return (
    <>
      <img
        onClick={() => {
          handleWebKnowledgeTasteClick();
          window.open("https://web.bibly.ai/knowledge_taste");
        }}
        src={eventWeb}
        style={{ width: "100%", cursor: "pointer" }}
        alt={"knowledge_taste"}
      />
      <Container>
        <HeaderWrapper headerHeight={headerHeight}>
          <EdgeImage
            src={bookEdge}
            headerHeight={headerHeight}
            standardWidth={standardWidth}
            alt={"book_edge"}
          />
          <LogoImage
            src={logo}
            alt={"logo"}
            logoWidth={logoWidth}
            logoHeight={logoHeight}
            logoTop={logoTop}
            marginLeft={marginLeft}
            standardWidth={standardWidth}
          />
        </HeaderWrapper>
        <BodyWrapper>
          <CatchphraseContainer>
            <Catchphrase
              marginLeft={marginLeft}
              catchWidth={catchWidth}
              catchHeight={catchHeight}
              catchFontSize={catchFontSize}
              chtchMarginTop={catchMarginTop}
              standardWidth={standardWidth}
            >
              당신을 위한 <br /> 찾아가는 서점 <br /> 비블리
            </Catchphrase>
            <DownLoadBannerContainer
              bannerContainerMarginTop={bannerContainerMarginTop}
              marginLeft={marginLeft}
              standardWidth={standardWidth}
            >
              <AndroidDownBanner
                onClick={() => {
                  handleWebTopGoogleClick();
                  clickAndroidDownload("top");
                }}
                src={androidDownBanner}
                bannerWidth={androidBannerWidth}
                bannerHeight={androidBannerHeight}
                standardWidth={standardWidth}
              />
              <IosDownBanner
                onClick={() => {
                  handleWebTopAppleClick();
                  clickIosDownload("top");
                }}
                src={iosDownBanner}
                bannerWidth={iosBannerWidth}
                bannerHeight={iosBannerHeight}
                bannerBetweenMargin={bannerBetweenMargin}
                standardWidth={standardWidth}
              />
            </DownLoadBannerContainer>
          </CatchphraseContainer>
          <AppImageContainer
            appContainerWidht={appContainerWidht}
            appContainerMarginTop={appContainerMarginTop}
            appContainerMarginLeft={appContainerMarginLeft}
            standardWidth={standardWidth}
          >
            <AppFirstImage
              src={appImageFirst}
              androidAppImageWidth={androidAppImageWidth}
              androidAppImageHight={androidAppImageHight}
              androidAppImageMarginTop={androidAppImageMarginTop}
              standardWidth={standardWidth}
            />
            <AppSecondImage
              src={appImageSecond}
              iosAppImageWidth={iosAppImageWidth}
              iosAppImageHight={iosAppImageHight}
              iosAppImageLeft={iosAppImageLeft}
              standardWidth={standardWidth}
            />
          </AppImageContainer>
        </BodyWrapper>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
`;

interface HeaderProps {
  headerHeight: number;
}

const HeaderWrapper = styled.div<HeaderProps>`
  height: ${(props) => props.headerHeight}px;
`;

interface EdgeImageProps {
  headerHeight: number;
  standardWidth: number;
}

const EdgeImage = styled.img<EdgeImageProps>`
  width: ${(props) => props.headerHeight}px;
  height: ${(props) => props.headerHeight}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    /* width: ${(props) => (props.headerHeight / props.standardWidth) * 100}vw;
    height: ${(props) => (props.headerHeight / props.standardWidth) * 100}vw; */
  }
`;

interface LogoImageProps {
  marginLeft: number;
  standardWidth: number;
  logoWidth: number;
  logoHeight: number;
  logoTop: number;
}

const LogoImage = styled.img<LogoImageProps>`
  position: absolute;
  width: 86px;
  height: 40px;
  left: ${(props) => props.marginLeft}px;
  top: 34.5px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    left: ${(props) => (props.marginLeft / props.standardWidth) * 100}vw;
    /* width: ${(props) =>
      (props.logoWidth / props.standardWidth) * 100 * 1.3}vw;
    height: ${(props) =>
      (props.logoHeight / props.standardWidth) * 100 * 1.3}vw;
    top: ${(props) => ((props.logoTop / props.standardWidth) * 100) / 1.3}vw; */
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CatchphraseContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface CatchphraseProps {
  catchWidth: number;
  catchHeight: number;
  catchFontSize: number;
  chtchMarginTop: number;
  marginLeft: number;
  standardWidth: number;
}

const Catchphrase = styled.div<CatchphraseProps>`
  width: ${(props) => props.catchWidth}px;
  height: ${(props) => props.catchHeight}px;
  font-size: ${(props) => props.catchFontSize}px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  margin-top: ${(props) => props.chtchMarginTop}px;
  margin-left: ${(props) => props.marginLeft}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) => (props.catchWidth / props.standardWidth) * 100}vw;
    height: ${(props) => (props.catchHeight / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.catchFontSize / props.standardWidth) * 100}vw;
    margin-top: ${(props) =>
      (props.chtchMarginTop / props.standardWidth) * 100}vw;
    margin-left: ${(props) => (props.marginLeft / props.standardWidth) * 100}vw;
  }
`;

interface DownLoadBannerContainerProps {
  marginLeft: number;
  bannerContainerMarginTop: number;
  standardWidth: number;
}

const DownLoadBannerContainer = styled.div<DownLoadBannerContainerProps>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => props.marginLeft}px;
  margin-top: ${(props) => props.bannerContainerMarginTop}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-left: ${(props) => (props.marginLeft / props.standardWidth) * 100}vw;
    margin-top: ${(props) =>
      (props.bannerContainerMarginTop / props.standardWidth) * 100}vw;
  }
`;

interface AndroidDownBannerProps {
  bannerWidth: number;
  bannerHeight: number;
  standardWidth: number;
}

const AndroidDownBanner = styled.img<AndroidDownBannerProps>`
  width: ${(props) => props.bannerWidth}px;
  height: ${(props) => props.bannerHeight}px;
  cursor: pointer;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) => (props.bannerWidth / props.standardWidth) * 100}vw;
    height: ${(props) => (props.bannerHeight / props.standardWidth) * 100}vw;
  }
`;

interface IosDownBannerProps extends AndroidDownBannerProps {
  bannerBetweenMargin: number;
  standardWidth: number;
}

const IosDownBanner = styled.img<IosDownBannerProps>`
  width: ${(props) => props.bannerWidth}px;
  height: ${(props) => props.bannerHeight}px;
  cursor: pointer;
  margin-top: ${(props) => props.bannerBetweenMargin}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) => (props.bannerWidth / props.standardWidth) * 100}vw;
    height: ${(props) => (props.bannerHeight / props.standardWidth) * 100}vw;
    margin-top: ${(props) =>
      (props.bannerBetweenMargin / props.standardWidth) * 100}vw;
  }
`;

interface AppImageContainerPoprs {
  appContainerWidht: number;
  appContainerMarginTop: number;
  appContainerMarginLeft: number;
  standardWidth: number;
}

const AppImageContainer = styled.div<AppImageContainerPoprs>`
  display: flex;
  width: ${(props) => props.appContainerWidht}px;
  position: relative;
  flex-direction: row;
  margin-top: ${(props) => props.appContainerMarginTop}px;
  margin-left: ${(props) => props.appContainerMarginLeft}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      (props.appContainerWidht / props.standardWidth) * 100}vw;
    margin-top: ${(props) =>
      (props.appContainerMarginTop / props.standardWidth) * 100}vw;
    margin-left: ${(props) =>
      (props.appContainerMarginLeft / props.standardWidth) * 100}vw;
  }
`;

interface AppFirstImageProps {
  androidAppImageWidth: number;
  androidAppImageHight: number;
  androidAppImageMarginTop: number;
  standardWidth: number;
}

const AppFirstImage = styled.img<AppFirstImageProps>`
  width: ${(props) => props.androidAppImageWidth}px;
  height: ${(props) => props.androidAppImageHight}px;
  margin-top: ${(props) => props.androidAppImageMarginTop}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      (props.androidAppImageWidth / props.standardWidth) * 100}vw;
    height: ${(props) =>
      (props.androidAppImageHight / props.standardWidth) * 100}vw;
    margin-top: ${(props) =>
      (props.androidAppImageMarginTop / props.standardWidth) * 100}vw;
  }
`;

interface AppSecondImageProps {
  iosAppImageWidth: number;
  iosAppImageHight: number;
  iosAppImageLeft: number;
  standardWidth: number;
}

const AppSecondImage = styled.img<AppSecondImageProps>`
  position: absolute;
  width: ${(props) => props.iosAppImageWidth}px;
  height: ${(props) => props.iosAppImageHight}px;
  left: ${(props) => props.iosAppImageLeft}px;
  top: 0px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) => (props.iosAppImageWidth / props.standardWidth) * 100}vw;
    height: ${(props) =>
      (props.iosAppImageHight / props.standardWidth) * 100}vw;
    left: ${(props) => (props.iosAppImageLeft / props.standardWidth) * 100}vw;
  }
`;

export default MainSectionPresenter;
