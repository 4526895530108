import React from "react";
import styled from "styled-components";
import styles from "../../styles/styles";
import circleContainerFisrt from "../../assets/img/howToUse/circle_container_01.png";
import circleContainerSecond from "../../assets/img/howToUse/circle_container_02.png";
import circleContainerThird from "../../assets/img/howToUse/circle_container_03.png";
import circleContainerForth from "../../assets/img/howToUse/circle_container_04.png";
import circleContainerFiveth from "../../assets/img/howToUse/circle_container_05.png";

const HowToUseApp = () => {
  const standardWidth = 365;
  const marginLeft = 20;
  const circleContainerDivergentPoint = 580;

  //Wapper
  const wapperPaddingTop = 25;
  const wapperPaddingBottom = 25;
  //Title
  const titleFontSize = 20;

  //SubTitle
  const subTitleMarginTop = 10;
  const subTitleFontSize = 14;

  //PerchaseOrderContainer
  const perchaseContainerMarginTop = 35;

  //PerchaseOrder CircleContainer
  const circleContainerWidth = 90;
  const circleContainerHeigth = 90;
  const circleContainerMarginRight = 25;

  //Circle AddText
  const circleInTextFontSize = 14;
  const circleInTextTop = 25;
  const circleInTextLeft = 20;

  //Circle AddText
  const circleAddTextFontSize = 12;
  const circleAddTextMarginLeft = 3;

  return (
    <Container>
      <Wrapper
        marginLeft={marginLeft}
        standardWidth={standardWidth}
        wapperPaddingTop={wapperPaddingTop}
        wapperPaddingBottom={wapperPaddingBottom}
      >
        <Title titleFontSize={titleFontSize} standardWidth={standardWidth}>
          찾아가는 서점 이용방법
        </Title>
        <SubTitle
          subTitleMarginTop={subTitleMarginTop}
          subTitleFontSize={subTitleFontSize}
          standardWidth={standardWidth}
        >
          배송 먼저 받고, 원하는 책만 구매하세요.
        </SubTitle>
        <PerchaseOrderContainer
          perchaseContainerMarginTop={perchaseContainerMarginTop}
          standardWidth={standardWidth}
          circleContainerDivergentPoint={circleContainerDivergentPoint}
          style={{ marginRight: 20 }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <CirclePositionWrapper
              standardWidth={standardWidth}
              circleContainerMarginRight={circleContainerMarginRight}
              circleContainerDivergentPoint={circleContainerDivergentPoint}
            >
              <CircleContainer
                src={circleContainerFisrt}
                circleContainerWidth={circleContainerWidth}
                circleContainerHeigth={circleContainerHeigth}
                standardWidth={standardWidth}
              />
              <CircleNumber
                circleInTextFontSize={circleInTextFontSize}
                circleInTextTop={circleInTextTop}
                circleInTextLeft={circleInTextLeft}
                standardWidth={standardWidth}
                color={styles.ORANGE_COLOR}
              >
                01
                <CircleAddText
                  circleAddTextFontSize={circleAddTextFontSize}
                  circleAddTextMarginLeft={circleAddTextMarginLeft}
                >
                  선주문
                </CircleAddText>
              </CircleNumber>
              <CircleText
                circleInTextFontSize={circleInTextFontSize}
                circleInTextTop={circleInTextTop}
                circleInTextLeft={circleInTextLeft}
                standardWidth={standardWidth}
              >
                주문하기
              </CircleText>
            </CirclePositionWrapper>
            <CirclePositionWrapper
              standardWidth={standardWidth}
              circleContainerMarginRight={circleContainerMarginRight}
              circleContainerDivergentPoint={circleContainerDivergentPoint}
            >
              <CircleContainer
                src={circleContainerSecond}
                circleContainerWidth={circleContainerWidth}
                circleContainerHeigth={circleContainerHeigth}
                standardWidth={standardWidth}
              />
              <CircleNumber
                circleInTextFontSize={circleInTextFontSize}
                circleInTextTop={circleInTextTop}
                circleInTextLeft={circleInTextLeft}
                standardWidth={standardWidth}
              >
                02
              </CircleNumber>
              <CircleText
                circleInTextFontSize={circleInTextFontSize}
                circleInTextTop={circleInTextTop}
                circleInTextLeft={circleInTextLeft}
                standardWidth={standardWidth}
              >
                배송완료
              </CircleText>
            </CirclePositionWrapper>
            <CirclePositionWrapper
              standardWidth={standardWidth}
              circleContainerDivergentPoint={circleContainerDivergentPoint}
              circleContainerMarginRight={0}
            >
              <CircleContainer
                src={circleContainerThird}
                circleContainerWidth={circleContainerWidth}
                circleContainerHeigth={circleContainerHeigth}
                standardWidth={standardWidth}
              />
              <CircleNumber
                circleInTextFontSize={circleInTextFontSize}
                circleInTextTop={circleInTextTop}
                circleInTextLeft={circleInTextLeft}
                standardWidth={standardWidth}
              >
                03
              </CircleNumber>

              <CircleText
                circleInTextFontSize={circleInTextFontSize}
                circleInTextTop={circleInTextTop}
                circleInTextLeft={circleInTextLeft}
                standardWidth={standardWidth}
              >
                읽어보기
              </CircleText>
            </CirclePositionWrapper>
          </div>
          <DivergentCircleWrapper
            circleContainerDivergentPoint={circleContainerDivergentPoint}
          >
            <CirclePositionWrapper
              standardWidth={standardWidth}
              circleContainerMarginRight={circleContainerMarginRight}
              circleContainerDivergentPoint={circleContainerDivergentPoint}
            >
              <CircleContainer
                src={circleContainerForth}
                circleContainerWidth={circleContainerWidth}
                circleContainerHeigth={circleContainerHeigth}
                standardWidth={standardWidth}
              />
              <CircleNumber
                circleInTextFontSize={circleInTextFontSize}
                circleInTextTop={circleInTextTop}
                circleInTextLeft={circleInTextLeft}
                standardWidth={standardWidth}
                color={styles.ORANGE_COLOR}
              >
                04
                <CircleAddText
                  circleAddTextFontSize={circleAddTextFontSize}
                  circleAddTextMarginLeft={circleAddTextMarginLeft}
                >
                  후결제
                </CircleAddText>
              </CircleNumber>
              <CircleText
                circleInTextFontSize={circleInTextFontSize}
                circleInTextTop={circleInTextTop}
                circleInTextLeft={circleInTextLeft}
                standardWidth={standardWidth}
              >
                구매결정
              </CircleText>
            </CirclePositionWrapper>
            <CirclePositionWrapper
              standardWidth={standardWidth}
              circleContainerDivergentPoint={circleContainerDivergentPoint}
            >
              <CircleContainer
                src={circleContainerFiveth}
                circleContainerWidth={circleContainerWidth}
                circleContainerHeigth={circleContainerHeigth}
                standardWidth={standardWidth}
              />
              <CircleNumber
                circleInTextFontSize={circleInTextFontSize}
                circleInTextTop={circleInTextTop}
                circleInTextLeft={circleInTextLeft}
                standardWidth={standardWidth}
              >
                05
              </CircleNumber>
              <CircleText
                circleInTextFontSize={circleInTextFontSize}
                circleInTextTop={circleInTextTop}
                circleInTextLeft={circleInTextLeft}
                standardWidth={standardWidth}
              >
                리턴하기
              </CircleText>
            </CirclePositionWrapper>
          </DivergentCircleWrapper>
        </PerchaseOrderContainer>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${styles.GRAY_COLOR};
`;

interface WrapperProps {
  marginLeft: number;
  standardWidth: number;
  wapperPaddingTop: number;
  wapperPaddingBottom: number;
}

const Wrapper = styled.div<WrapperProps>`
  margin-left: ${(props) => props.marginLeft}px;
  padding-top: ${(props) => props.wapperPaddingTop}px;
  padding-bottom: ${(props) => props.wapperPaddingBottom}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    padding-top: ${(props) =>
      (props.wapperPaddingTop / props.standardWidth) * 100}vw;
    padding-bottom: ${(props) =>
      (props.wapperPaddingBottom / props.standardWidth) * 100}vw;
  }
`;

interface TitleProps {
  titleFontSize: number;
  standardWidth: number;
}

const Title = styled.div<TitleProps>`
  font-size: ${(props) => props.titleFontSize}px;
  font-weight: 700;
  @media (max-width: ${(props) => props.standardWidth}px) {
    font-size: ${(props) =>
      (props.titleFontSize / props.standardWidth) * 100}vw;
  }
`;

interface SubTitleProps {
  subTitleMarginTop: number;
  subTitleFontSize: number;
  standardWidth: number;
}

const SubTitle = styled.div<SubTitleProps>`
  margin-top: ${(props) => props.subTitleMarginTop}px;
  font-size: ${(props) => props.subTitleFontSize}px;
  color: ${styles.DARK_GRAY_COLOR};
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.subTitleMarginTop / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.subTitleFontSize / props.standardWidth) * 100}vw;
  }
`;

interface PerchaseOrderContainerProps {
  perchaseContainerMarginTop: number;
  standardWidth: number;
  circleContainerDivergentPoint: number;
}

const PerchaseOrderContainer = styled.div<PerchaseOrderContainerProps>`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => props.perchaseContainerMarginTop}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.perchaseContainerMarginTop / props.standardWidth) * 100}vw;
  }
  @media (max-width: ${(props) => props.circleContainerDivergentPoint}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

interface DivergentCircleWrapperProps {
  circleContainerDivergentPoint: number;
}

const DivergentCircleWrapper = styled.div<DivergentCircleWrapperProps>`
  display: flex;
  flex-direction: row;
  @media (max-width: ${(props) => props.circleContainerDivergentPoint}px) {
    margin-top: 25px;
  }
`;

interface CirclePositionWrapperProps {
  circleContainerMarginRight?: any;
  circleContainerDivergentPoint: number;
  standardWidth: number;
}

const CirclePositionWrapper = styled.div<CirclePositionWrapperProps>`
  position: relative;
  margin-right: ${(props) =>
    props.circleContainerMarginRight === 0
      ? 25
      : props.circleContainerMarginRight}px;
  @media (max-width: ${(props) => props.circleContainerDivergentPoint}px) {
    margin-right: ${(props) =>
      (props.circleContainerMarginRight / props.circleContainerDivergentPoint) *
      100}vw;
  }
`;

interface CircleContainerProps {
  circleContainerWidth: number;
  circleContainerHeigth: number;
  standardWidth: number;
}

const CircleContainer = styled.img<CircleContainerProps>`
  width: ${(props) => props.circleContainerWidth}px;
  height: ${(props) => props.circleContainerHeigth}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      (props.circleContainerWidth / props.standardWidth) * 100}vw;
    height: ${(props) =>
      (props.circleContainerHeigth / props.standardWidth) * 100}vw;
  }
`;

interface CircleInTextProps {
  circleInTextFontSize: number;
  circleInTextTop: number;
  circleInTextLeft: number;
  standardWidth: number;
  color?: string;
}

const CircleNumber = styled.span<CircleInTextProps>`
  position: absolute;
  font-family: "jua";
  font-size: ${(props) => props.circleInTextFontSize}px;
  top: ${(props) => props.circleInTextTop}px;
  left: ${(props) => props.circleInTextLeft}px;
  color: ${(props) => (props.color ? props.color : "white")};
  @media (max-width: ${(props) => props.standardWidth}px) {
    font-size: ${(props) =>
      (props.circleInTextFontSize / props.standardWidth) * 100}vw;
    top: ${(props) => (props.circleInTextTop / props.standardWidth) * 100}vw;
    left: ${(props) => (props.circleInTextLeft / props.standardWidth) * 100}vw;
  }
`;

const CircleText = styled.span<CircleInTextProps>`
  position: absolute;
  font-family: "jua";
  font-size: ${(props) => props.circleInTextFontSize}px;
  bottom: ${(props) => props.circleInTextTop}px;
  left: ${(props) => props.circleInTextLeft}px;
  color: ${styles.DARK_GRAY_COLOR};
  @media (max-width: ${(props) => props.standardWidth}px) {
    font-size: ${(props) =>
      (props.circleInTextFontSize / props.standardWidth) * 100}vw;
    bottom: ${(props) => (props.circleInTextTop / props.standardWidth) * 100}vw;
    left: ${(props) => (props.circleInTextLeft / props.standardWidth) * 100}vw;
  }
`;

interface CircleAddTextProps {
  circleAddTextFontSize: number;
  circleAddTextMarginLeft: number;
}

const CircleAddText = styled.span<CircleAddTextProps>`
  font-size: ${(props) => props.circleAddTextFontSize}px;
  margin-left: ${(props) => props.circleAddTextMarginLeft}px;
`;

export default HowToUseApp;
