import React, { FunctionComponent } from "react";
import ReviewApp from "./ReviewApp";
import ReviewWeb from "./ReviewWeb";

interface Props {
  standardWidth: number;
  divergingPoint: boolean;
  marginLeft: number;
  reviewAppDivergentPoint: boolean;
}

const MainSectionPresenter: FunctionComponent<Props> = ({
  standardWidth,
  divergingPoint,
  marginLeft,
  reviewAppDivergentPoint,
}) => {
  return divergingPoint ? (
    <ReviewApp reviewAppDivergentPoint={reviewAppDivergentPoint} />
  ) : (
    <ReviewWeb standardWidth={standardWidth} marginLeft={marginLeft} />
  );
};

export default MainSectionPresenter;
