import React, { FunctionComponent } from "react";
import styled from "styled-components";
import styles from "../../../styles/styles";
import appImageFirst from "../../../assets/img/curation/secondCuration/app_1.png";
import appImageSecond from "../../../assets/img/curation/secondCuration/app_2.png";
import appImageThird from "../../../assets/img/curation/secondCuration/app_3.png";
import appImageForth from "../../../assets/img/curation/secondCuration/app_4.png";

interface Props {
  standardWidth: number;
  marginLeft: number;
}

const SecondCurationWeb: FunctionComponent<Props> = ({
  standardWidth,
  marginLeft,
}) => {
  const marginTop = 161;
  //Title
  const titleMarginTop = 100;
  const titleFontSize = 45;

  //Sub Title
  const subTitleMarginTop = 20;
  const subTitleFontSize = 23;

  //App Image Container
  const appImageContainerMarginTop = 45;

  //App Image
  const appImageWidth = 291 + 40;
  const appImageHeight = 517 + 66 + 20;
  const appImageMarginRight = -3;

  return (
    <Container standardWidth={standardWidth} marginTop={marginTop}>
      <Wrapper standardWidth={standardWidth} marginLeft={marginLeft}>
        <Title
          standardWidth={standardWidth}
          titleMarginTop={titleMarginTop}
          titleFontSize={titleFontSize}
        >
          새로운 지식을 찾는 당신에게
        </Title>
        <SubTitle
          standardWidth={standardWidth}
          subTitleMarginTop={subTitleMarginTop}
          subTitleFontSize={subTitleFontSize}
        >
          비블리만의 인공지능 알고리즘이 당신의 관심사, 책장을 분석해 취향에 꼭
          맞는 책을 추천해드려요.
        </SubTitle>
        <AppImageContainer
          standardWidth={standardWidth}
          appImageContainerMarginTop={appImageContainerMarginTop}
        >
          <AppImage
            src={appImageFirst}
            standardWidth={standardWidth}
            appImageWidth={appImageWidth}
            appImageHeight={appImageHeight}
            appImageMarginRight={appImageMarginRight}
          />
          <AppImage
            src={appImageSecond}
            standardWidth={standardWidth}
            appImageWidth={appImageWidth}
            appImageHeight={appImageHeight}
            appImageMarginRight={appImageMarginRight}
          />
          <AppImage
            src={appImageThird}
            standardWidth={standardWidth}
            appImageWidth={appImageWidth}
            appImageHeight={appImageHeight}
            appImageMarginRight={appImageMarginRight}
          />
          <AppImage
            src={appImageForth}
            standardWidth={standardWidth}
            appImageWidth={appImageWidth}
            appImageHeight={appImageHeight}
            appImageMarginRight={0}
          />
        </AppImageContainer>
      </Wrapper>
    </Container>
  );
};

interface ContainerProps {
  standardWidth: number;
  marginTop: number;
}

const Container = styled.div<ContainerProps>`
  margin-top: ${(props) => props.marginTop}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) => (props.marginTop / props.standardWidth) * 100}vw;
  }
`;

interface WrapperProps {
  standardWidth: number;
  marginLeft: number;
}
const Wrapper = styled.div<WrapperProps>`
  margin-left: ${(props) => props.marginLeft}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-left: ${(props) => (props.marginLeft / props.standardWidth) * 100}vw;
  }
`;

interface TitleProps {
  standardWidth: number;
  titleMarginTop: number;
  titleFontSize: number;
}

const Title = styled.div<TitleProps>`
  margin-top: ${(props) => props.titleMarginTop}px;
  font-size: ${(props) => props.titleFontSize}px;
  font-weight: 900;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.titleMarginTop / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.titleFontSize / props.standardWidth) * 100}vw;
  }
`;

interface SubTitleProps {
  standardWidth: number;
  subTitleMarginTop: number;
  subTitleFontSize: number;
}

const SubTitle = styled.span<SubTitleProps>`
  display: block;
  font-size: ${(props) => props.subTitleFontSize}px;
  margin-top: ${(props) => props.subTitleMarginTop}px;
  color: ${styles.DARK_GRAY_COLOR};
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.subTitleMarginTop / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.subTitleFontSize / props.standardWidth) * 100}vw;
  }
`;

interface AppImageContainerProps {
  standardWidth: number;
  appImageContainerMarginTop: number;
}
const AppImageContainer = styled.div<AppImageContainerProps>`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => props.appImageContainerMarginTop}px;
  margin-left: ${(props) => -25}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.appImageContainerMarginTop / props.standardWidth) * 100}vw;
    margin-left: ${(props) => (-25 / props.standardWidth) * 100}vw;
  }
`;

interface AppImageProps {
  standardWidth: number;
  appImageWidth: number;
  appImageHeight: number;
  appImageMarginRight: number;
}
const AppImage = styled.img<AppImageProps>`
  width: ${(props) => props.appImageWidth}px;
  height: ${(props) => props.appImageHeight}px;
  object-fit: contain;
  margin-right: ${(props) => props.appImageMarginRight}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) => (props.appImageWidth / props.standardWidth) * 100}vw;
    height: ${(props) => (props.appImageHeight / props.standardWidth) * 100}vw;
    margin-right: ${(props) =>
      (props.appImageMarginRight / props.standardWidth) * 100}vw;
  }
`;

export default SecondCurationWeb;
