import React, { useCallback, useEffect, useState } from "react";
import MainSectionPresenter from "./mainSection/MainSectionPresenter";
import HowToUsePresenter from "./howToUse/HowToUsePresenter";
import ReviewPresenter from "./review/ReviewPresenter";
import FirstCurationPresenter from "./curation/firstCuration/FirstCurationPresenter";
import SecondCurationPresenter from "./curation/secondCuration/SecondCurationPresenter";
import FooterPresenter from "./footer/FooterPresenter";
import styled from "styled-components";
import upScrollIcon from "../assets/img/etc/up_scroll_icon.png";
import upScrollIconHover from "../assets/img/etc/up_scroll_icon_hover.png";
import ReactPixel from "react-facebook-pixel";
import useTrackingBtn from "../hooks/useTrackingBtn";

const View = ({ location }) => {
  const divergingPointWidth = 780;
  const marginLeft = 320;
  const [divergingPoint, setDivergingPoint] = useState(
    window.innerWidth > divergingPointWidth ? false : true
  );
  const standardWidth = 1920;

  const [
    secondCurationImageDivergentPoint,
    setSecondCurationImageDivergentPoint,
  ] = useState(false);

  const [reviewAppDivergentPoint, setReviewAppDivergentPoint] = useState(false);

  const { handleWebUpScrollClick, handleAppUpScrollClick } = useTrackingBtn();

  const getWidthHandler = () => {
    if (document.documentElement.clientWidth + 15 > divergingPointWidth) {
      setDivergingPoint(false);
    } else {
      setDivergingPoint(true);
    }
    if (document.documentElement.clientWidth + 15 < 548) {
      setSecondCurationImageDivergentPoint(true);
    } else {
      setSecondCurationImageDivergentPoint(false);
    }
    if (document.documentElement.clientWidth + 15 < 680) {
      setReviewAppDivergentPoint(true);
    } else {
      setReviewAppDivergentPoint(false);
    }
  };

  const [scrollY, setScrollY] = useState(0);

  const getHeightHandler = () => {
    setScrollY(window.scrollY);
  };

  const fetchBusinesses = useCallback(() => {
    const googleFilterLocation = location?.search.includes("gclid");
    if (!googleFilterLocation) {
      ReactPixel.pageView(); // For tracking page view
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener("resize", getWidthHandler);
    window.addEventListener("scroll", getHeightHandler);
    getWidthHandler();
    fetchBusinesses();
    return () => {
      window.removeEventListener("resize", getWidthHandler);
      window.removeEventListener("scroll", getHeightHandler);
    };
  }, [fetchBusinesses]);

  //Up Scroll Icon Wrapper
  const upScrollWrapperRight = 5;
  const webUpScrollWrapperRight = 135;

  //Up Scroll Icon
  const upScrollStandardWidth = 345;
  const upScrollIconWidth = 75;

  const scrollUpHandle = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const webUpScrollIconWidth = 80;

  const [upScroll, setUpScroll] = useState(false);

  const upScrollHandle = (over: boolean) => {
    setUpScroll(over);
  };

  useEffect(() => {
    const upScroll = document.getElementById("up_scroll_icon");
    upScroll?.addEventListener("mouseover", () => upScrollHandle(true));
    upScroll?.addEventListener("mouseleave", () => upScrollHandle(false));
    return () => {
      upScroll?.removeEventListener("mouseover", () => upScrollHandle(true));
      upScroll?.removeEventListener("mouseleave", () => upScrollHandle(false));
    };
  }, []);

  const clickAndroidDownload = (position: string) => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.ryencatchers.bibly"
    );
    if (position === "top") {
      ReactPixel.track("Android Download Button(Top)");
    } else if (position === "bottom") {
      ReactPixel.track("Android Download Button(Bottom)");
    }
    // setClickedDownload(true);
  };

  const clickIosDownload = (position: string) => {
    window.open(
      "https://apps.apple.com/kr/app/%EB%B9%84%EB%B8%94%EB%A6%AC-%EC%9D%B8%EA%B3%B5%EC%A7%80%EB%8A%A5-%EC%84%9C%EC%A0%90/id1524931439"
    );
    if (position === "top") {
      ReactPixel.track("IOS Download Button(Top)");
    } else if (position === "bottom") {
      ReactPixel.track("IOS Download Button(Bottom)");
    }
    // setClickedDownload(true);
  };

  return (
    <>
      <MainSectionPresenter
        standardWidth={standardWidth}
        divergingPoint={divergingPoint}
        marginLeft={marginLeft}
        clickAndroidDownload={clickAndroidDownload}
        clickIosDownload={clickIosDownload}
      />
      <HowToUsePresenter
        standardWidth={standardWidth}
        divergingPoint={divergingPoint}
        marginLeft={marginLeft}
      />
      <ReviewPresenter
        standardWidth={standardWidth}
        divergingPoint={divergingPoint}
        marginLeft={marginLeft}
        reviewAppDivergentPoint={reviewAppDivergentPoint}
      />
      <FirstCurationPresenter
        standardWidth={standardWidth}
        divergingPoint={divergingPoint}
        marginLeft={marginLeft}
      />
      <SecondCurationPresenter
        standardWidth={standardWidth}
        divergingPoint={divergingPoint}
        marginLeft={marginLeft}
        secondCurationImageDivergentPoint={secondCurationImageDivergentPoint}
      />
      <FooterPresenter
        standardWidth={standardWidth}
        divergingPoint={divergingPoint}
        marginLeft={marginLeft}
        clickAndroidDownload={clickAndroidDownload}
        clickIosDownload={clickIosDownload}
      />
      <UpScrollIconWrapper
        standardWidth={upScrollStandardWidth}
        scrollY={scrollY}
        upScrollWrapperRight={upScrollWrapperRight}
      >
        <UpScrollIcon
          onClick={() => {
            handleAppUpScrollClick();
            scrollUpHandle();
          }}
          standardWidth={upScrollStandardWidth}
          upScrollIconWidth={upScrollIconWidth}
          src={upScroll ? upScrollIconHover : upScrollIcon}
          alt={"up_scroll_icon"}
        />
      </UpScrollIconWrapper>
      <WebUpScrollIconWrapper
        standardWidth={upScrollStandardWidth}
        scrollY={scrollY}
        upScrollWrapperRight={webUpScrollWrapperRight}
      >
        <WebUpScrollIcon
          onClick={() => {
            handleWebUpScrollClick();
            scrollUpHandle();
          }}
          id={"up_scroll_icon"}
          standardWidth={standardWidth}
          upScrollIconWidth={webUpScrollIconWidth}
          src={upScroll ? upScrollIconHover : upScrollIcon}
          alt={"up_scroll_icon"}
        />
      </WebUpScrollIconWrapper>
    </>
  );
};

interface UpScrollIconWrapperProps {
  standardWidth: number;
  scrollY: number;
  upScrollWrapperRight: number;
}

const UpScrollIconWrapper = styled.div<UpScrollIconWrapperProps>`
  position: fixed;
  display: none;
  right: ${(props) => props.upScrollWrapperRight}px;
  bottom: ${(props) => (props.scrollY >= 1617 ? 80 : 20)}px;
  z-index: 99;
  @media (max-width: ${(props) => props.standardWidth}px) {
    right: ${(props) =>
      (props.upScrollWrapperRight / props.standardWidth) * 100}vw;
  }
  @media (max-width: ${(props) => 780}px) {
    display: ${(props) => (props.scrollY < 200 ? "none" : "flex")};
  }
`;

interface UpScrollIconProps {
  standardWidth: number;
  upScrollIconWidth: number;
}
const UpScrollIcon = styled.img<UpScrollIconProps>`
  width: ${(props) => props.upScrollIconWidth}px;
  height: ${(props) => props.upScrollIconWidth}px;
  cursor: pointer;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      (props.upScrollIconWidth / props.standardWidth) * 100}vw;
    height: ${(props) =>
      (props.upScrollIconWidth / props.standardWidth) * 100}vw;
  }
`;

interface WebUpScrollIconWrapperProps {
  standardWidth: number;
  scrollY: number;
  upScrollWrapperRight: number;
}

const WebUpScrollIconWrapper = styled.div<WebUpScrollIconWrapperProps>`
  position: fixed;
  right: ${(props) => props.upScrollWrapperRight}px;
  bottom: ${(props) => (props.scrollY >= 1617 ? 80 : 20)}px;
  z-index: 99;
  display: ${(props) => (props.scrollY < 200 ? "none" : null)};
  @media (max-width: ${(props) => props.standardWidth}px) {
    right: ${(props) =>
      (props.upScrollWrapperRight / props.standardWidth) * 100}vw;
  }
  @media (max-width: ${(props) => 780}px) {
    display: none;
  }
`;

interface WebUpScrollIconProps {
  standardWidth: number;
  upScrollIconWidth: number;
}
const WebUpScrollIcon = styled.img<WebUpScrollIconProps>`
  width: ${(props) => props.upScrollIconWidth}px;
  height: ${(props) => props.upScrollIconWidth}px;
  cursor: pointer;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      (props.upScrollIconWidth / props.standardWidth) * 100}vw;
    height: ${(props) =>
      (props.upScrollIconWidth / props.standardWidth) * 100}vw;
  }
`;

export default View;
