import { useCallback } from "react";
import moment from "moment";
import firebase from "firebase";
import "firebase/firestore";

export default function useTrackingBtn() {
  var firebaseConfig = {
    apiKey: "AIzaSyA7M-QJ_v8MjzPjGPaEEa_4MxNeri3F5Jg",
    authDomain: "bibly-ec9c4.firebaseapp.com",
    databaseURL: "https://bibly-ec9c4.firebaseio.com",
    projectId: "bibly-ec9c4",
    storageBucket: "bibly-ec9c4.appspot.com",
    messagingSenderId: "147974630931",
    appId: "1:147974630931:web:84e40c6299b1d3a6db19e6",
    measurementId: "G-5BN7Z47QZ8",
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }

  const database = firebase.database();

  const today = moment().format("YYYYMMDD");

  const handleWebInput = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (data && data.landing && data.landing.web && data.landing.web.input) {
        const input = data.landing.web.input.number;
        database.ref(`${today}/` + "landing/" + "web/" + "input").update({
          number: Number(input) + 1,
        });
      } else {
        database.ref(`${today}/` + "landing/" + "web/" + "input").set({
          number: 1,
        });
      }
    });
  }, []);

  const handleWebTopGoogleClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.web &&
        data.landing.web.topGoogleClick
      ) {
        const topGoogleClick = data.landing.web.topGoogleClick.number;
        database
          .ref(`${today}/` + "landing/" + "web/" + "topGoogleClick")
          .update({
            number: Number(topGoogleClick) + 1,
          });
      } else {
        database.ref(`${today}/` + "landing/" + "web/" + "topGoogleClick").set({
          number: 1,
        });
      }
    });
  }, []);

  const handleWebTopAppleClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.web &&
        data.landing.web.topAppleClick
      ) {
        const topAppleClick = data.landing.web.topAppleClick.number;
        database
          .ref(`${today}/` + "landing/" + "web/" + "topAppleClick")
          .update({
            number: Number(topAppleClick) + 1,
          });
      } else {
        database.ref(`${today}/` + "landing/" + "web/" + "topAppleClick").set({
          number: 1,
        });
      }
    });
  }, []);

  const handleWebBottomGoogleClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.web &&
        data.landing.web.bottomGoogleClick
      ) {
        const bottomGoogleClick = data.landing.web.bottomGoogleClick.number;
        database
          .ref(`${today}/` + "landing/" + "web/" + "bottomGoogleClick")
          .update({
            number: Number(bottomGoogleClick) + 1,
          });
      } else {
        database
          .ref(`${today}/` + "landing/" + "web/" + "bottomGoogleClick")
          .set({
            number: 1,
          });
      }
    });
  }, []);

  const handleWebBottomAppleClcik = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.web &&
        data.landing.web.bottomAppleClcik
      ) {
        const bottomAppleClcik = data.landing.web.bottomAppleClcik.number;
        database
          .ref(`${today}/` + "landing/" + "web/" + "bottomAppleClcik")
          .update({
            number: Number(bottomAppleClcik) + 1,
          });
      } else {
        database
          .ref(`${today}/` + "landing/" + "web/" + "bottomAppleClcik")
          .set({
            number: 1,
          });
      }
    });
  }, []);

  const handleWebBlogClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.web &&
        data.landing.web.blogClick
      ) {
        const blogClick = data.landing.web.blogClick.number;
        database.ref(`${today}/` + "landing/" + "web/" + "blogClick").update({
          number: Number(blogClick) + 1,
        });
      } else {
        database.ref(`${today}/` + "landing/" + "web/" + "blogClick").set({
          number: 1,
        });
      }
    });
  }, []);

  const handleWebInstClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.web &&
        data.landing.web.instaClick
      ) {
        const instaClick = data.landing.web.instaClick.number;
        database.ref(`${today}/` + "landing/" + "web/" + "instaClick").update({
          number: Number(instaClick) + 1,
        });
      } else {
        database.ref(`${today}/` + "landing/" + "web/" + "instaClick").set({
          number: 1,
        });
      }
    });
  }, []);

  const handleWebKakaoClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.web &&
        data.landing.web.kakaoClick
      ) {
        const kakaoClick = data.landing.web.kakaoClick.number;
        database.ref(`${today}/` + "landing/" + "web/" + "kakaoClick").update({
          number: Number(kakaoClick) + 1,
        });
      } else {
        database.ref(`${today}/` + "landing/" + "web/" + "kakaoClick").set({
          number: 1,
        });
      }
    });
  }, []);

  const handleWebUpScrollClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.web &&
        data.landing.web.upScrollClick
      ) {
        const upScrollClick = data.landing.web.upScrollClick.number;
        database
          .ref(`${today}/` + "landing/" + "web/" + "upScrollClick")
          .update({
            number: Number(upScrollClick) + 1,
          });
      } else {
        database.ref(`${today}/` + "landing/" + "web/" + "upScrollClick").set({
          number: 1,
        });
      }
    });
  }, []);

  const handleWebKnowledgeTasteClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.web &&
        data.landing.web.knowledgeTasteClick
      ) {
        const knowledgeTasteClick = data.landing.web.knowledgeTasteClick.number;
        database
          .ref(`${today}/` + "landing/" + "web/" + "knowledgeTasteClick")
          .update({
            number: Number(knowledgeTasteClick) + 1,
          });
      } else {
        database
          .ref(`${today}/` + "landing/" + "web/" + "knowledgeTasteClick")
          .set({
            number: 1,
          });
      }
    });
  }, []);

  const handleAppInput = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (data && data.landing && data.landing.app && data.landing.app.input) {
        const input = data.landing.app.input.number;
        database.ref(`${today}/` + "landing/" + "app/" + "input").update({
          number: Number(input) + 1,
        });
      } else {
        database.ref(`${today}/` + "landing/" + "app/" + "input").set({
          number: 1,
        });
      }
    });
  }, []);

  const handleAppTopGoogleClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.app &&
        data.landing.app.topGoogleClick
      ) {
        const topGoogleClick = data.landing.app.topGoogleClick.number;
        database
          .ref(`${today}/` + "landing/" + "app/" + "topGoogleClick")
          .update({
            number: Number(topGoogleClick) + 1,
          });
      } else {
        database.ref(`${today}/` + "landing/" + "app/" + "topGoogleClick").set({
          number: 1,
        });
      }
    });
  }, []);

  const handleAppTopAppleClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.app &&
        data.landing.app.topAppleClick
      ) {
        const topAppleClick = data.landing.app.topAppleClick.number;
        database
          .ref(`${today}/` + "landing/" + "app/" + "topAppleClick")
          .update({
            number: Number(topAppleClick) + 1,
          });
      } else {
        database.ref(`${today}/` + "landing/" + "app/" + "topAppleClick").set({
          number: 1,
        });
      }
    });
  }, []);

  const handleAppBottomGoogleClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.app &&
        data.landing.app.bottomGoogleClick
      ) {
        const bottomGoogleClick = data.landing.app.bottomGoogleClick.number;
        database
          .ref(`${today}/` + "landing/" + "app/" + "bottomGoogleClick")
          .update({
            number: Number(bottomGoogleClick) + 1,
          });
      } else {
        database
          .ref(`${today}/` + "landing/" + "app/" + "bottomGoogleClick")
          .set({
            number: 1,
          });
      }
    });
  }, []);

  const handleAppBottomAppleClcik = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.app &&
        data.landing.app.bottomAppleClcik
      ) {
        const bottomAppleClcik = data.landing.app.bottomAppleClcik.number;
        database
          .ref(`${today}/` + "landing/" + "app/" + "bottomAppleClcik")
          .update({
            number: Number(bottomAppleClcik) + 1,
          });
      } else {
        database
          .ref(`${today}/` + "landing/" + "app/" + "bottomAppleClcik")
          .set({
            number: 1,
          });
      }
    });
  }, []);

  const handleAppBlogClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.app &&
        data.landing.app.blogClick
      ) {
        const blogClick = data.landing.app.blogClick.number;
        database.ref(`${today}/` + "landing/" + "app/" + "blogClick").update({
          number: Number(blogClick) + 1,
        });
      } else {
        database.ref(`${today}/` + "landing/" + "app/" + "blogClick").set({
          number: 1,
        });
      }
    });
  }, []);

  const handleAppInstClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.app &&
        data.landing.app.instaClick
      ) {
        const instaClick = data.landing.app.instaClick.number;
        database.ref(`${today}/` + "landing/" + "app/" + "instaClick").update({
          number: Number(instaClick) + 1,
        });
      } else {
        database.ref(`${today}/` + "landing/" + "app/" + "instaClick").set({
          number: 1,
        });
      }
    });
  }, []);

  const handleAppKakaoClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.app &&
        data.landing.app.kakaoClick
      ) {
        const kakaoClick = data.landing.app.kakaoClick.number;
        database.ref(`${today}/` + "landing/" + "app/" + "kakaoClick").update({
          number: Number(kakaoClick) + 1,
        });
      } else {
        database.ref(`${today}/` + "landing/" + "app/" + "kakaoClick").set({
          number: 1,
        });
      }
    });
  }, []);

  const handleAppUpScrollClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.app &&
        data.landing.app.upScrollClick
      ) {
        const upScrollClick = data.landing.app.upScrollClick.number;
        database
          .ref(`${today}/` + "landing/" + "app/" + "upScrollClick")
          .update({
            number: Number(upScrollClick) + 1,
          });
      } else {
        database.ref(`${today}/` + "landing/" + "app/" + "upScrollClick").set({
          number: 1,
        });
      }
    });
  }, []);

  const handleAppKnowledgeTasteClick = useCallback(async () => {
    const todayDataRef: any = await database.ref(`${today}/`);
    await todayDataRef.once("value", async (snapshot) => {
      const data = await snapshot.val();
      if (
        data &&
        data.landing &&
        data.landing.app &&
        data.landing.app.knowledgeTasteClick
      ) {
        const knowledgeTasteClick = data.landing.app.knowledgeTasteClick.number;
        database
          .ref(`${today}/` + "landing/" + "app/" + "knowledgeTasteClick")
          .update({
            number: Number(knowledgeTasteClick) + 1,
          });
      } else {
        database
          .ref(`${today}/` + "landing/" + "app/" + "knowledgeTasteClick")
          .set({
            number: 1,
          });
      }
    });
  }, []);

  return {
    handleWebInput,
    handleAppInput,
    handleWebTopGoogleClick,
    handleWebTopAppleClick,
    handleWebBottomGoogleClick,
    handleWebBottomAppleClcik,
    handleWebBlogClick,
    handleWebInstClick,
    handleWebKakaoClick,
    handleWebUpScrollClick,
    handleWebKnowledgeTasteClick,
    handleAppTopGoogleClick,
    handleAppTopAppleClick,
    handleAppBottomGoogleClick,
    handleAppBottomAppleClcik,
    handleAppBlogClick,
    handleAppInstClick,
    handleAppKakaoClick,
    handleAppUpScrollClick,
    handleAppKnowledgeTasteClick,
  };
}
