import React, { FunctionComponent } from "react";
import HowToUseApp from "./HowToUseApp";
import HowToUseWeb from "./HowToUseWeb";

interface Props {
  standardWidth: number;
  divergingPoint: boolean;
  marginLeft: number;
}

const MainSectionPresenter: FunctionComponent<Props> = ({
  standardWidth,
  divergingPoint,
  marginLeft,
}) => {
  return divergingPoint ? (
    <HowToUseApp />
  ) : (
    <HowToUseWeb standardWidth={standardWidth} marginLeft={marginLeft} />
  );
};

export default MainSectionPresenter;
