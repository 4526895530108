import React, { FunctionComponent, useRef, useState } from "react";
import styled from "styled-components";
import styles from "../../styles/styles";
import doubleQuotes from "../../assets/img/review/double_quotes.png";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import selectorLeft from "../../assets/img/review/selector_left.png";
import selectorRight from "../../assets/img/review/selector_right.png";

interface Props {
  standardWidth: number;
  marginLeft: number;
}

const reviewText = [
  [
    {
      text: "구글 가입 후, <br/>처음으로 작성한 리뷰.<br/>이 앱 단언컨대 대성을<br/>노려볼만 하다",
      id: "김**",
    },
    {
      text: "책 먼저 훑어보고 <br/>구매할 수 있어서 <br/>너무 좋습니다.",
      id: "김**",
    },
    {
      text: "단연 최고다 <br/>나만 알고싶은데 <br/>다른 사람들도 <br/>알았으면 좋겠다..",
      id: "송*",
    },
  ],
  [
    {
      text: "바쁜 일 끝나고 독서할 <br/>생각에 미리 깔아두고 <br/>기뻐하는 중이에요~! <br/>응원하겠습니다!",
      id: "Lee**",
    },
    {
      text: "책은 읽고 싶지만 뭘 읽을지 <br/>고르기가 힘들었는데 <br/>추천책만 평생 읽어도 <br/>다 못읽겠네요!",
      id: "노**",
    },
    {
      text: "이 앱은 추천 다양하게 <br/>해줘서 편해요 <br/>그리고 추천 책 사봤는데 <br/>잘 맞았음요",
      id: "이**",
    },
  ],
  [
    {
      text: "서점에 가지못하는 요즘같은 <br/>시기에 알맞는 어플. <br/>참신하기도하고 생각보다 <br/>추천도서도 잘 되어있음",
      id: "김**",
    },
    {
      text: "중고책 상태들도 최상이라 <br/>책살 때에는 무조건 <br/>비블리에 먼저 <br/>검색하게 되네요 ㅎㅎ",
      id: "***",
    },
    {
      text: "대박 서점인데 인공지능으로 <br/> 추천해주고 하니 <br/> 서점 갈 필요가 없네요. <br/> 강추!",
      id: "마****",
    },
  ],
];

const ReviewWeb: FunctionComponent<Props> = ({ standardWidth, marginLeft }) => {
  //Wrapper
  const wrapperMarginTop = 112;
  const wrapperMarginBottom = 200;

  //Title
  const titleFontSize = 45;

  //SubTitle
  const subTitleFontSize = 23;
  const subTitleMarginTop = 20;

  //  ReviewContainer
  const reviewContainerMarginLeft = 236;
  const reviewContainerMarginRight = 15;

  //ReviewBox
  const reviewContainerMarginTop = 130;
  const reviewContainerWidth = 443;
  const reviewContainerHeight = 308;
  const reviewContainerBorderRadius = 60;
  const reviewContaierMarginRight = 29;
  const reviewContainerTotalWidth =
    reviewContainerWidth * 3 + reviewContainerMarginRight * 4 * 1.9;

  //DoubleQuotes
  const doubleQuotesWidth = 35;
  const doubleQuotesLeft = 65;
  const doubleQuoteTop = -10;

  //ReviewBoxTitle
  const reviewBoxTitlePaddingBottom = 40;
  const reviewBoxTitleFontSize = 27;

  //ReviewBoxUserId
  const reviewBoxUserIdFontSize = 18;
  const reviewBoxUsermarginTop = 24;
  const reviewBoxUserPositionBottom = 50;

  //ReviewSelector
  const reviewSelectorWidth = 85;
  const reviewSelectorHeight = 41;
  const reviewSelectorBoerderRadius = 20.5;
  const reviewSelectorPositionLeft = -130;

  //ReviewSelectorImage
  const reviewSelectorImageWidth = 85;
  const reviewSelectorImageHeight = 41;
  const reviewSelectorImageMarginLeft = 10;
  const reviewSelectorImageMarginLeft2 = 30;

  //ReviewSelectorRight
  const reviewSelectorRightWidth = 85;
  const reviewSelectorRightHeight = 41;
  const reviewSelectorRightBoerderRadius = 20.5;
  const reviewSelectorRightPositionRight = 1420;

  //PaginationDotContainer
  const paginationDotContainerMarginTop = 35;

  //PaginationDot Long
  const paginationLongDotWidth = 79;

  //PaginationDot Short
  const paginationShortDotWidth = 10;
  const paginationShortDotHeight = 10;
  const paginationShortDotBorderRadius = 5;
  const paginationShortDotMarginRight = 5;

  //SwiperContainer
  const swiperContainerPaddingTop = 20;
  const swiperContainerPaddingBottom = 20;
  const swiperContainerPaddingLeft = 10;

  const [currentPage, setCurrentPage] = useState(0);

  const slideEventHandler = (e: any) => {
    setCurrentPage(e.realIndex);
  };

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <Container>
      <Wrapper
        standardWidth={standardWidth}
        wrapperMarginTop={wrapperMarginTop}
        wrapperMarginBottom={wrapperMarginBottom}
      >
        <Title
          marginLeft={marginLeft}
          standardWidth={standardWidth}
          titleFontSize={titleFontSize}
        >
          비블리언들의 선택
        </Title>
        <SubTitle
          marginLeft={marginLeft}
          standardWidth={standardWidth}
          subTitleFontSize={subTitleFontSize}
          subTitleMarginTop={subTitleMarginTop}
        >
          믿고 보는 비블리언들의 생생한 후기를 들어보세요.
        </SubTitle>
        <ReviewContainer
          standardWidth={standardWidth}
          reviewContainerMarginLeft={reviewContainerMarginLeft}
          reviewContainerMarginTop={reviewContainerMarginTop}
          reviewContainerMarginRight={reviewContainerMarginRight}
          reviewContainerTotalWidth={reviewContainerTotalWidth}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ReviewSelectorRight
              ref={nextRef}
              standardWidth={standardWidth}
              reviewSelectorRightWidth={reviewSelectorRightWidth}
              reviewSelectorRightHeight={reviewSelectorRightHeight}
              reviewSelectorRightBoerderRadius={
                reviewSelectorRightBoerderRadius
              }
              reviewSelectorRightPositionRight={
                reviewSelectorRightPositionRight
              }
            >
              <ReviewSelectorImage
                src={selectorRight}
                standardWidth={standardWidth}
                reviewSelectorImageWidth={reviewSelectorImageWidth}
                reviewSelectorImageHeight={reviewSelectorImageHeight}
                reviewSelectorImageMarginLeft={reviewSelectorImageMarginLeft2}
              />
            </ReviewSelectorRight>
            <ReviewSelector
              ref={prevRef}
              standardWidth={standardWidth}
              reviewSelectorWidth={reviewSelectorWidth}
              reviewSelectorHeight={reviewSelectorHeight}
              reviewSelectorBoerderRadius={reviewSelectorBoerderRadius}
              reviewSelectorPositionLeft={reviewSelectorPositionLeft}
            >
              <ReviewSelectorImage
                src={selectorLeft}
                standardWidth={standardWidth}
                reviewSelectorImageWidth={reviewSelectorImageWidth}
                reviewSelectorImageHeight={reviewSelectorImageHeight}
                reviewSelectorImageMarginLeft={reviewSelectorImageMarginLeft}
              />
            </ReviewSelector>
            <Swiper
              navigation={{
                prevEl: prevRef.current ? prevRef.current : undefined,
                nextEl: nextRef.current ? nextRef.current : undefined,
              }}
              onInit={(swiper: any) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.update();
              }}
              spaceBetween={50}
              lazy={true}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
              onSlideChange={(e) => slideEventHandler(e)}
            >
              {reviewText.map((review, index) => (
                <SwiperSlide key={index}>
                  <SwiperContainer
                    standardWidth={standardWidth}
                    swiperContainerPaddingTop={swiperContainerPaddingTop}
                    swiperContainerPaddingBottom={swiperContainerPaddingBottom}
                    swiperContainerPaddingLeft={swiperContainerPaddingLeft}
                  >
                    {review.map((item, index) => (
                      <ReviewBox
                        key={index}
                        standardWidth={standardWidth}
                        reviewContainerWidth={reviewContainerWidth}
                        reviewContainerHeight={reviewContainerHeight}
                        reviewContainerBorderRadius={
                          reviewContainerBorderRadius
                        }
                        reviewContaierMarginRight={reviewContaierMarginRight}
                      >
                        <DoubleQuotes
                          standardWidth={standardWidth}
                          src={doubleQuotes}
                          doubleQuotesWidth={doubleQuotesWidth}
                          doubleQuotesLeft={doubleQuotesLeft}
                          doubleQuoteTop={doubleQuoteTop}
                        />
                        <ReviewBoxTitle
                          reviewContainerWidth={reviewContainerWidth}
                          standardWidth={standardWidth}
                          reviewBoxTitlePaddingBottom={
                            reviewBoxTitlePaddingBottom
                          }
                          reviewBoxTitleFontSize={reviewBoxTitleFontSize}
                        >
                          {parse(item.text)}
                        </ReviewBoxTitle>
                        <UserId
                          standardWidth={standardWidth}
                          reviewContainerWidth={reviewContainerWidth}
                          reviewBoxUserIdFontSize={reviewBoxUserIdFontSize}
                          reviewBoxUsermarginTop={reviewBoxUsermarginTop}
                          reviewBoxUserPositionBottom={
                            reviewBoxUserPositionBottom
                          }
                        >
                          - {parse(item.id)} -
                        </UserId>
                      </ReviewBox>
                    ))}
                  </SwiperContainer>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <PaginationDotContainer
            standardWidth={standardWidth}
            paginationDotContainerMarginTop={paginationDotContainerMarginTop}
            reviewContainerTotalWidth={reviewContainerTotalWidth}
          >
            <PaginationShortDot
              currentPage={currentPage === 0 ? true : false}
              standardWidth={standardWidth}
              paginationShortDotWidth={paginationShortDotWidth}
              paginationShortDotHeight={paginationShortDotHeight}
              paginationShortDotBorderRadius={paginationShortDotBorderRadius}
              paginationShortDotMarginRight={paginationShortDotMarginRight}
              paginationLongDotWidth={paginationLongDotWidth}
            />
            <PaginationShortDot
              currentPage={currentPage === 1 ? true : false}
              standardWidth={standardWidth}
              paginationShortDotWidth={paginationShortDotWidth}
              paginationShortDotHeight={paginationShortDotHeight}
              paginationShortDotBorderRadius={paginationShortDotBorderRadius}
              paginationShortDotMarginRight={paginationShortDotMarginRight}
              paginationLongDotWidth={paginationLongDotWidth}
            />
            <PaginationShortDot
              currentPage={currentPage === 2 ? true : false}
              standardWidth={standardWidth}
              paginationShortDotWidth={paginationShortDotWidth}
              paginationShortDotHeight={paginationShortDotHeight}
              paginationShortDotBorderRadius={paginationShortDotBorderRadius}
              paginationShortDotMarginRight={paginationShortDotMarginRight}
              paginationLongDotWidth={paginationLongDotWidth}
            />
          </PaginationDotContainer>
        </ReviewContainer>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div``;

interface WrapperProps {
  wrapperMarginTop: number;
  standardWidth: number;
  wrapperMarginBottom: number;
}

const Wrapper = styled.div<WrapperProps>`
  margin-top: ${(props) => props.wrapperMarginTop}px;
  margin-bottom: ${(props) => props.wrapperMarginBottom}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    margin-top: ${(props) =>
      (props.wrapperMarginTop / props.standardWidth) * 100}vw;
    margin-bottom: ${(props) =>
      (props.wrapperMarginBottom / props.standardWidth) * 100}vw;
  }
`;

interface TitleProps {
  standardWidth: number;
  titleFontSize: number;
  marginLeft: number;
}

const Title = styled.span<TitleProps>`
  font-size: ${(props) => props.titleFontSize}px;
  font-weight: 900;
  margin-left: ${(props) => props.marginLeft}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    font-size: ${(props) =>
      (props.titleFontSize / props.standardWidth) * 100}vw;
    margin-left: ${(props) => (props.marginLeft / props.standardWidth) * 100}vw;
  }
`;

interface SubTitleProps {
  standardWidth: number;
  subTitleFontSize: number;
  subTitleMarginTop: number;
  marginLeft: number;
}

const SubTitle = styled.div<SubTitleProps>`
  font-size: ${(props) => props.subTitleFontSize}px;
  color: ${styles.DARK_GRAY_COLOR};
  margin-top: ${(props) => props.subTitleMarginTop}px;
  margin-left: ${(props) => props.marginLeft}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    font-size: ${(props) =>
      (props.subTitleFontSize / props.standardWidth) * 100}vw;
    margin-top: ${(props) =>
      (props.subTitleMarginTop / props.standardWidth) * 100}vw;
    margin-left: ${(props) => (props.marginLeft / props.standardWidth) * 100}vw;
  }
`;

interface ReviewContainerProps {
  standardWidth: number;
  reviewContainerMarginLeft: number;
  reviewContainerMarginTop: number;
  reviewContainerMarginRight: number;
  reviewContainerTotalWidth: number;
}

const ReviewContainer = styled.div<ReviewContainerProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  width: ${(props) => props.reviewContainerTotalWidth}px;
  margin-left: ${(props) => props.reviewContainerMarginLeft}px;
  margin-top: ${(props) => props.reviewContainerMarginTop}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      (props.reviewContainerTotalWidth / props.standardWidth) * 100}vw;
    margin-left: ${(props) =>
      (props.reviewContainerMarginLeft / props.standardWidth) * 100}vw;
    margin-top: ${(props) =>
      (props.reviewContainerMarginTop / props.standardWidth) * 100}vw;
  }
`;

interface ReviewBoxProps {
  standardWidth: number;
  reviewContainerWidth: number;
  reviewContainerHeight: number;
  reviewContainerBorderRadius: number;
  reviewContaierMarginRight: number;
}
const ReviewBox = styled.div<ReviewBoxProps>`
  display: flex;
  position: relative;
  width: ${(props) => props.reviewContainerWidth}px;
  height: ${(props) => props.reviewContainerHeight}px;
  border-radius: ${(props) => props.reviewContainerBorderRadius}px;
  box-shadow: 0 5px 14px 0 rgba(208, 208, 208, 0.5);
  background-color: #fefefe;
  margin-right: ${(props) => props.reviewContaierMarginRight}px;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      (props.reviewContainerWidth / props.standardWidth) * 100}vw;
    height: ${(props) =>
      (props.reviewContainerHeight / props.standardWidth) * 100}vw;
    border-radius: ${(props) =>
      (props.reviewContainerBorderRadius / props.standardWidth) * 100}vw;
    margin-right: ${(props) =>
      (props.reviewContaierMarginRight / props.standardWidth) * 100}vw;
  }
`;

interface DoubleQuotesProps {
  standardWidth: number;
  doubleQuotesWidth: number;
  doubleQuotesLeft: number;
  doubleQuoteTop: number;
}

const DoubleQuotes = styled.img<DoubleQuotesProps>`
  position: absolute;
  left: ${(props) => props.doubleQuotesLeft}px;
  top: ${(props) => props.doubleQuoteTop}px;
  width: ${(props) => props.doubleQuotesWidth}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    left: ${(props) => (props.doubleQuotesLeft / props.standardWidth) * 100}vw;
    top: ${(props) => (props.doubleQuoteTop / props.standardWidth) * 100}vw;
    width: ${(props) =>
      (props.doubleQuotesWidth / props.standardWidth) * 100}vw;
  }
`;

interface ReviewBoxTitleProps {
  reviewContainerWidth: number;
  reviewBoxTitlePaddingBottom: number;
  reviewBoxTitleFontSize: number;
  standardWidth: number;
}

const ReviewBoxTitle = styled.div<ReviewBoxTitleProps>`
  width: ${(props) => props.reviewContainerWidth}px;
  font-size: ${(props) => props.reviewBoxTitleFontSize}px;
  margin-bottom: ${(props) => props.reviewBoxTitlePaddingBottom}px;
  font-weight: 700;
  text-align: center;
  line-height: 1.4;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      (props.reviewContainerWidth / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.reviewBoxTitleFontSize / props.standardWidth) * 100}vw;
    margin-bottom: ${(props) =>
      (props.reviewBoxTitlePaddingBottom / props.standardWidth) * 100}vw;
  }
`;

interface UserIdProps {
  standardWidth: number;
  reviewContainerWidth: number;
  reviewBoxUserIdFontSize: number;
  reviewBoxUsermarginTop: number;
  reviewBoxUserPositionBottom: number;
}

const UserId = styled.div<UserIdProps>`
  position: absolute;
  bottom: ${(props) => props.reviewBoxUserPositionBottom}px;
  width: ${(props) => props.reviewContainerWidth}px;
  font-size: ${(props) => props.reviewBoxUserIdFontSize}px;
  margin-top: ${(props) => props.reviewBoxUsermarginTop}px;
  color: ${styles.MIDDLE_GRAY_COLOR};
  text-align: center;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      (props.reviewContainerWidth / props.standardWidth) * 100}vw;
    font-size: ${(props) =>
      (props.reviewBoxUserIdFontSize / props.standardWidth) * 100}vw;
    margin-top: ${(props) =>
      (props.reviewBoxUsermarginTop / props.standardWidth) * 100}vw;
    bottom: ${(props) =>
      (props.reviewBoxUserPositionBottom / props.standardWidth) * 100}vw;
  }
`;

interface ReviewSelectorProps {
  standardWidth: number;
  reviewSelectorWidth: number;
  reviewSelectorHeight: number;
  reviewSelectorBoerderRadius: number;
  reviewSelectorPositionLeft: number;
  reviewSelectorPositionRight?: number;
}

const ReviewSelector = styled.div<ReviewSelectorProps>`
  display: flex;
  left: ${(props) => props.reviewSelectorPositionLeft}px;
  position: absolute;
  width: ${(props) => props.reviewSelectorWidth}px;
  height: ${(props) => props.reviewSelectorHeight}px;
  border-radius: ${(props) => props.reviewSelectorBoerderRadius}px;
  align-items: center;
  cursor: pointer;
  @media (max-width: ${(props) => props.standardWidth}px) {
    left: ${(props) =>
      (props.reviewSelectorPositionLeft / props.standardWidth) * 100}vw;
    width: ${(props) =>
      (props.reviewSelectorWidth / props.standardWidth) * 100}vw;
    height: ${(props) =>
      (props.reviewSelectorHeight / props.standardWidth) * 100}vw;
    border-radius: ${(props) =>
      (props.reviewSelectorBoerderRadius / props.standardWidth) * 100}vw;
  }
`;

interface ReviewSelectorImageProps {
  standardWidth: number;
  reviewSelectorImageWidth: number;
  reviewSelectorImageHeight: number;
  reviewSelectorImageMarginLeft: number;
}

const ReviewSelectorImage = styled.img<ReviewSelectorImageProps>`
  width: ${(props) => props.reviewSelectorImageWidth}px;
  height: ${(props) => props.reviewSelectorImageHeight}px;
  margin-left: ${(props) => props.reviewSelectorImageMarginLeft}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      (props.reviewSelectorImageWidth / props.standardWidth) * 100}vw;
    height: ${(props) =>
      (props.reviewSelectorImageHeight / props.standardWidth) * 100}vw;
    margin-left: ${(props) =>
      (props.reviewSelectorImageMarginLeft / props.standardWidth) * 100}vw;
  }
`;

interface ReviewSelectorRightProps {
  standardWidth: number;
  reviewSelectorRightWidth: number;
  reviewSelectorRightHeight: number;
  reviewSelectorRightBoerderRadius: number;
  reviewSelectorRightPositionRight: number;
}

const ReviewSelectorRight = styled.div<ReviewSelectorRightProps>`
  display: flex;
  left: ${(props) => props.reviewSelectorRightPositionRight}px;
  position: absolute;
  width: ${(props) => props.reviewSelectorRightWidth}px;
  height: ${(props) => props.reviewSelectorRightHeight}px;
  border-radius: ${(props) => props.reviewSelectorRightBoerderRadius}px;
  align-items: center;
  cursor: pointer;
  @media (max-width: ${(props) => props.standardWidth}px) {
    left: ${(props) =>
      (props.reviewSelectorRightPositionRight / props.standardWidth) * 100}vw;
    width: ${(props) =>
      (props.reviewSelectorRightWidth / props.standardWidth) * 100}vw;
    height: ${(props) =>
      (props.reviewSelectorRightHeight / props.standardWidth) * 100}vw;
    border-radius: ${(props) =>
      (props.reviewSelectorRightBoerderRadius / props.standardWidth) * 100}vw;
  }
`;

interface PaginationDotContainerProps {
  standardWidth: number;
  paginationDotContainerMarginTop: number;
  reviewContainerTotalWidth: number;
}

const PaginationDotContainer = styled.div<PaginationDotContainerProps>`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.reviewContainerTotalWidth}px;
  margin-top: ${(props) => props.paginationDotContainerMarginTop}px;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      (props.reviewContainerTotalWidth / props.standardWidth) * 100}vw;
    margin-top: ${(props) =>
      (props.paginationDotContainerMarginTop / props.standardWidth) * 100}vw;
  }
`;

interface PaginationShortDotProps {
  currentPage: boolean;
  standardWidth: number;
  paginationShortDotWidth: number;
  paginationShortDotHeight: number;
  paginationShortDotBorderRadius: number;
  paginationShortDotMarginRight: number;
  paginationLongDotWidth: number;
}

const PaginationShortDot = styled.span<PaginationShortDotProps>`
  width: ${(props) =>
    props.currentPage
      ? props.paginationLongDotWidth
      : props.paginationShortDotWidth}px;
  height: ${(props) => props.paginationShortDotHeight}px;
  border-radius: ${(props) => props.paginationShortDotBorderRadius}px;
  margin-left: ${(props) => props.paginationShortDotMarginRight}px;
  background-color: ${(props) =>
    props.currentPage ? "black" : styles.MIDDLE_GRAY_COLOR};
  @media (max-width: ${(props) => props.standardWidth}px) {
    width: ${(props) =>
      ((props.currentPage
        ? props.paginationLongDotWidth
        : props.paginationShortDotWidth) /
        props.standardWidth) *
      100}vw;
    height: ${(props) =>
      (props.paginationShortDotHeight / props.standardWidth) * 100}vw;
    border-radius: ${(props) =>
      (props.paginationShortDotBorderRadius / props.standardWidth) * 100}vw;
    margin-left: ${(props) =>
      (props.paginationShortDotMarginRight / props.standardWidth) * 100}vw;
  }
`;

interface SwiperContainerProps {
  standardWidth: number;
  swiperContainerPaddingTop: number;
  swiperContainerPaddingBottom: number;
  swiperContainerPaddingLeft: number;
}

const SwiperContainer = styled.div<SwiperContainerProps>`
  display: flex;
  flex-direction: row;
  padding: ${(props) => props.swiperContainerPaddingTop}px
    ${(props) => props.swiperContainerPaddingLeft}px
    ${(props) => props.swiperContainerPaddingBottom}px
    ${(props) => props.swiperContainerPaddingLeft}px;
  @media (max-width: ${(props) => props.standardWidth}px) {
    padding: ${(props) =>
        (props.swiperContainerPaddingTop / props.standardWidth) * 100}vw
      ${(props) =>
        (props.swiperContainerPaddingLeft / props.standardWidth) * 100}vw
      ${(props) =>
        (props.swiperContainerPaddingBottom / props.standardWidth) * 100}vw
      ${(props) =>
        (props.swiperContainerPaddingLeft / props.standardWidth) * 100}vw;
  }
`;

export default ReviewWeb;
